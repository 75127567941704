import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  H1,
  Separator,
  Address,
  Label,
  MoreInfoForm,
  Step3Of4,
  CompleteLater,
  KeepInTouch,
  Events,
  TermsOfUse,
} from "./styles";
import {
  Input,
  Checkbox,
  Button,
  Select,
  MultiSelect,
  InputWrapper,
  FormGroup,
  ScrollToTopOnMount,
  countryList,
  Spacer,
} from "../shared";
import { updateUser } from "../../redux/actions/auth";
import {
  getNewsletters,
  subscribeToNewsletters,
} from "../../redux/actions/newsletters";
import { ReactComponent as CaretRight } from "../../svgs/caret-right.svg";
import { step3ValidationSchema } from "./validationSchemas";
import { initializeData, persistRegData, resetTimer } from "./regUtils";
import { useRegistrationTimer } from "./hooks";
import { parseQueryString } from "../../utils/queryString";

const Step3: React.FC<{
  location: { search: string };
  updateUser: Function;
  history: { push: Function };
  getNewsletters: Function;
  subscribeToNewsletters: Function;
}> = React.memo(
  ({ updateUser, getNewsletters, subscribeToNewsletters, location }) => {
    useRegistrationTimer();
    const [mailingLists, showMailingLists] = useState(false);
    const newsletters = useSelector((state: any) => state.newsletters.data);
    const [selectedNewsletters, setSelectedNewsletters] = useState<string[]>(
      []
    );
    const [regData, setRegData] = useState<{ [key: string]: any } | any>(null);
    const [agreedTerms, setTermAgreement] = useState(false);
    const [byPost, setByPost] = useState(false);
    const { plan } = parseQueryString(location.search);

    useEffect(() => {
      initializeData().then((regData: any) => {
        setRegData(regData);
      });
    }, []);

    useEffect(() => {
      getNewsletters();
    }, [getNewsletters]);

    return (
      <>
        <ScrollToTopOnMount />
        <Formik
          onSubmit={({ byPost, ...professionalInfo }: any) => {
            persistRegData({
              ...regData,
              ...professionalInfo,
              byPost,
            });

            updateUser(professionalInfo).then(resetTimer);

            if (selectedNewsletters.length > 0) {
              subscribeToNewsletters({
                interests: selectedNewsletters,
                first_name: regData.firstName,
                last_name: regData.lastName,
                email: regData.email,
              });
            }
          }}
          initialValues={{
            jobTitle: "",
            secondaryEmail: "",
            organisation: "",
            industrySector: "",
            workPhone: "",
            interests: "",
            businessAddress: "",
            businessPostCode: "",
            businessCity: "",
            businessCounty: "",
            businessCountry: "",
            gdpr: false,
            byPost: false,
          }}
          validationSchema={step3ValidationSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
          }) => (
            <MoreInfoForm onSubmit={handleSubmit}>
              <H1>
                You're nearly done!
                <br />
                We just need a bit more
                <br />
                information from you.
              </H1>

              <Separator>
                <hr />
              </Separator>

              <Step3Of4 step={3} activateLinks />

              <CompleteLater>
                {plan !== "corporate" && (
                  <a href="#keepintouch">
                    Complete later <CaretRight />
                  </a>
                )}
              </CompleteLater>

              <FormGroup>
                <InputWrapper>
                  <Input
                    errored={Boolean(touched.jobTitle && errors.jobTitle)}
                    placeholder="Job Title"
                    type="text"
                    name="jobTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jobTitle}
                    required={plan === "corporate"}
                  />

                  <span className="error">
                    {touched.jobTitle && errors.jobTitle}
                  </span>
                </InputWrapper>

                <InputWrapper>
                  <Input
                    errored={Boolean(
                      touched.organisation && errors.organisation
                    )}
                    name="organisation"
                    value={values.organisation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Company/Organisation"
                    required={plan === "corporate"}
                    type="text"
                  />

                  <span className="error">
                    {touched.organisation && errors.organisation}
                  </span>
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    errored={Boolean(
                      touched.secondaryEmail && errors.secondaryEmail
                    )}
                    value={values.secondaryEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="secondaryEmail"
                    placeholder="Secondary Email"
                    type="email"
                    required={plan === "corporate"}
                  />

                  <span className="error">
                    {touched.secondaryEmail && errors.secondaryEmail}
                  </span>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    errored={Boolean(touched.workPhone && errors.workPhone)}
                    value={values.workPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Work Phone"
                    name="workPhone"
                    type="text"
                  />

                  <span className="error">
                    {touched.workPhone && errors.workPhone}
                  </span>
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="industrySector"
                    value={values.industrySector}
                    required={plan === "corporate"}
                  >
                    <option value="">Industry Sector</option>
                    <option value="Academia & Higher Education">
                      Academia & Higher Education
                    </option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Architecture & Design">
                      Architecture & Design
                    </option>
                    <option value="Banking & Financial Services">
                      Banking & Financial Services
                    </option>
                    <option value="Consumer Goods">Consumer Goods</option>
                    <option value="Development">Development</option>
                    <option value="Education">Education</option>
                    <option value="Energy">Energy</option>
                    <option value="Environment">Environment</option>
                    <option value="Fashion & Retail">Fashion & Retail</option>
                    <option value="Film & Television">Film & Television</option>
                    <option value="Government">Government</option>
                    <option value="Healthcare & Public Health">
                      Healthcare & Public Health
                    </option>
                    <option value="Hospitality & Catering">
                      Hospitality & Catering
                    </option>
                    <option value="Human Rights">Human Rights</option>
                    <option value="Infrastructure & Construction">
                      Infrastructure & Construction
                    </option>
                    <option value="Journalism & Media">
                      Journalism & Media
                    </option>
                    <option value="Legal">Legal</option>
                    <option value="Literature & Publishing">
                      Literature & Publishing
                    </option>
                    <option value="Manufacturing & Automotive">
                      Manufacturing & Automotive
                    </option>
                    <option value="Marketing & Communications">
                      Marketing & Communications
                    </option>
                    <option value="Music & Entertainment">
                      Music & Entertainment
                    </option>
                    <option value="NGO & Charity">NGO & Charity</option>
                    <option value="Other">Other</option>
                    <option value="Pharmaceutical">Pharmaceutical</option>
                    <option value="Politics & Policy">Politics & Policy</option>
                    <option value="PR & Lobbying">PR & Lobbying</option>
                    <option value="Private Equity">Private Equity</option>
                    <option value="Professional Services">
                      Professional Services
                    </option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Science & Research">
                      Science & Research
                    </option>
                    <option value="Technology & Innovation">
                      Technology & Innovation
                    </option>
                    <option value="Theatre & Dance">Theatre & Dance</option>
                    <option value="Venture Capital">Venture Capital</option>
                    <option value="Visual Arts">Visual Arts</option>
                  </Select>
                </InputWrapper>

                <InputWrapper>
                  <MultiSelect
                    onChange={(options: string[]) => {
                      setFieldValue("interests", options.join(", "));
                    }}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <Address>
                  <Label>Business Address</Label>
                  <FormGroup>
                    <InputWrapper>
                      <Input
                        value={values.businessPostCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Post Code"
                        name="businessPostCode"
                        required={plan === "corporate"}
                        type="text"
                      />
                    </InputWrapper>
                  </FormGroup>
                </Address>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    value={values.businessAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Address"
                    name="businessAddress"
                    type="text"
                    required={plan === "corporate"}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    value={values.businessCity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="businessCity"
                    placeholder="City"
                    type="text"
                    required={plan === "corporate"}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    value={values.businessCounty}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="businessCounty"
                    placeholder="County"
                    type="text"
                    required={plan === "corporate"}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Select
                    errored={Boolean(
                      touched.businessCountry && errors.businessCountry
                    )}
                    value={values.businessCountry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="businessCountry"
                    required={plan === "corporate"}
                  >
                    <option value="Country">Country</option>

                    {countryList}
                  </Select>

                  <span className="error">
                    {touched.businessCountry && errors.businessCountry}
                  </span>
                </InputWrapper>
              </FormGroup>

              <KeepInTouch id="keepintouch">
                <p className="title">Keeping in touch</p>

                <p className="paragraph">
                  We would like to keep you updated on our activities, future
                  appeals and how your support is making a difference. Please
                  let us know if you would like to be contacted and, if so, how
                  you might like to hear from us.
                </p>

                <Checkbox
                  checked={mailingLists}
                  onChange={() => {
                    showMailingLists(!mailingLists);
                    setFieldValue("gdpr", !mailingLists);
                  }}
                  label={
                    <strong>
                      Yes I am happy for the Royal African Society to email me
                    </strong>
                  }
                />
                {mailingLists && (
                  <>
                    <p className="paragraph last">
                      We have a number of newsletters from the different
                      programmes we run, which any member of the public can
                      subscribe to. Please select the newsletters you would like
                      to receive below.
                    </p>

                    <Events>
                      {newsletters &&
                        newsletters.mailing_lists.map((interest: any) => (
                          <Checkbox
                            key={interest.id}
                            onChange={({ target: { checked } }: any) => {
                              if (checked) {
                                setSelectedNewsletters([
                                  ...selectedNewsletters,
                                  interest.id,
                                ]);
                              } else {
                                setSelectedNewsletters(
                                  selectedNewsletters.filter(
                                    (selected) => selected !== interest.id
                                  )
                                );
                              }
                            }}
                            small
                            checked={selectedNewsletters.includes(interest.id)}
                            label={<span>{interest.name}</span>}
                          />
                        ))}
                    </Events>
                  </>
                )}

                <Spacer height={3.3} />

                <Checkbox
                  small
                  label={
                    <span>
                      Yes, I am happy for the Royal African Society to contact
                      me by post
                    </span>
                  }
                  onChange={() => {
                    const bool = !byPost;
                    setByPost(bool);
                    setFieldValue("byPost", bool);
                  }}
                />

                {byPost && (
                  <p className="paragraph last">
                    Please note you can change your preferences by emailing{" "}
                    <a href="mailto:ras@soas.ac.uk">ras@soas.ac.uk</a>. See our{" "}
                    <a
                      href="https://royalafricansociety.org/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>{" "}
                    for more information.
                  </p>
                )}
              </KeepInTouch>

              <TermsOfUse>
                <Checkbox
                  checked={agreedTerms}
                  onChange={() => {
                    setTermAgreement(!agreedTerms);
                  }}
                  small
                  label={
                    <strong>
                      I agree to the{" "}
                      <a
                        href="https://royalafricansociety.org/terms-of-use/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                      </a>{" "}
                      *
                    </strong>
                  }
                />
              </TermsOfUse>
              <Button type="submit" secondary large disabled={!agreedTerms}>
                Next
              </Button>
            </MoreInfoForm>
          )}
        </Formik>
      </>
    );
  }
);

export default connect(
  null,
  {
    updateUser,
    getNewsletters,
    subscribeToNewsletters,
  }
)(Step3);
