// @ts-nocheck
import React, { useContext } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";
import Context from "./Context";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const PaymentRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { amount, email } = useContext(Context);
  return (
    <Route
      {...rest}
      render={props =>
        amount && email ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/donate"
            }}
          />
        )
      }
    />
  );
};

export const ThankYouRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { source } = useContext(Context);
  return (
    <Route
      {...rest}
      render={props =>
        source ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/donate"
            }}
          />
        )
      }
    />
  );
};
