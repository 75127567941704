import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import Case from "case";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PersonalInforamtionContainer } from "./styles";
import Flex from "../shared/Flex";

interface Props extends RouteComponentProps<any> {
  membership: {
    id: number;
    plan: string;
    active: boolean;
    created_at: string;
    expiry: string;
    subscription_provider: string;
  };
  member: {
    firstName: string;
    lastName: string;
    id: number;
  };
}

const MembershipInforamtion: React.FC<Props> = ({ membership, member }) => {
  return (
    <PersonalInforamtionContainer>
      <Flex>
        <div>
          <p className="title">Plan:</p>
          <div className="panel">{Case.capital(membership.plan)}</div>
          <p className="title">Status:</p>
          <div className="panel">
            {membership.active ? "Active" : "Inactive"}
          </div>
          <p className="title">Membership ID:</p>
          <div className="panel">
            {member && Case.upper(
              `RAS${member.firstName[0]}${member.lastName[0]}${member.id}${membership.id}`
            )}
          </div>
          <p className="title">Member since:</p>
          <div className="panel">
            {format(membership.created_at, "DD/MM/YYYY")}
          </div>
          <p className="title">Expiry Date:</p>
          <div className="panel">{format(membership.expiry, "DD/MM/YYYY")}</div>
          <p className="title">Payment Method:</p>
          <div className="panel">
            {Case.capital(membership.subscription_provider)}
          </div>
        </div>
      </Flex>
    </PersonalInforamtionContainer>
  );
};

export default withRouter(connect(null, null)(MembershipInforamtion));
