import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { Formik } from "formik";
import Loader from "react-loader-spinner";
import {
  Spacer,
  Button,
  Modal,
  InputWrapper as InputWrap,
  FormGroup,
  Input,
  Select,
  countryList
} from "../shared";
import { ProfileForm } from "./styles";
import useLoading from "../../hooks/useLoading";

const InputWrapper = styled(InputWrap)`
  width: 100%;
`;

const Form = styled(ProfileForm)`
  padding: 0 3rem;
`;

const Address = ({ updateProfile, type = "personal" }: any) => {
  const [modalState, setModalState] = useState(false);
  const [isLoading, load] = useLoading();
  const theme = useContext(ThemeContext);

  const user = useSelector(({ user }: any) => user.data);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  let fullAddress = {
    address: user.address,
    postCode: user.post_code,
    city: user.city,
    county: user.county,
    country: user.country
  };

  if (type === "professional") {
    fullAddress = {
      address: user.business_address,
      postCode: user.business_post_code,
      city: user.business_city,
      county: user.business_county,
      country: user.business_country
    };
  }

  return (
    <>
      <Wrapper>
        <p className="header">Address</p>
        <Spacer height={5.4} />

        <div className="body">
          <p>
            {fullAddress.address}
            {fullAddress.address && ","}
            <br />
            {fullAddress.city}
            {fullAddress.city && ","} {fullAddress.country}
            <br />
            {fullAddress.postCode}
          </p>

          <div className="address-button">
            <Button large secondary onClick={toggleModal} as="a">
              Edit Address
            </Button>
          </div>
        </div>

        <Spacer height={4.5} />
      </Wrapper>
      <Modal
        title={`Edit ${type} address`}
        modalActive={modalState}
        toggleModal={toggleModal}
      >
        <Formik
          enableReinitialize={true}
          initialValues={fullAddress}
          onSubmit={formData => {
            const proPayload = {
              businessAddress: formData.address,
              businessCity: formData.city,
              businessCounty: formData.county,
              businessCountry: formData.country,
              businessPostCode: formData.postCode
            };

            load(
              updateProfile(
                type === "personal" ? formData : proPayload,
                toggleModal
              )
            );
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            values,
            handleChange,
            dirty,
            touched,
            errors
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <InputWrapper>
                  <Input
                    name="address"
                    value={values.address}
                    type="text"
                    placeholder="Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    name="postCode"
                    value={values.postCode}
                    type="text"
                    placeholder="Postcode *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    name="city"
                    value={values.city}
                    type="text"
                    placeholder="City *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Input
                    name="county"
                    value={values.county}
                    type="text"
                    placeholder="County *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </FormGroup>

              <FormGroup>
                <InputWrapper>
                  <Select
                    name="country"
                    value={values.country}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {countryList}
                  </Select>
                </InputWrapper>
              </FormGroup>

              <Button type="submit" large disabled={isLoading}>
                {isLoading ? (
                  <Loader
                    type="Oval"
                    color={theme.white}
                    height={20}
                    width={20}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  padding-left: 4rem;
  font-size: 2rem;
  text-align: left;
  line-height: 150%;
  border-bottom: 1px solid ${({ theme }) => theme.gray2};

  .header {
    color: ${({ theme }) => theme.gray3};
  }

  .body {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      justify-content: left;

      .address-button {
        margin-top: 3.2rem;
      }
    }
  }
`;

export default Address;
