// @ts-nocheck
import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { string, object, boolean } from "yup";
import { connect } from "react-redux";
import Tabs from "./Tabs";
import { Form, H1, Address, Label } from "./styles";
import {
  Steps,
  Input,
  Separator,
  FormGroup,
  Button,
  Spacer,
  Select,
  InputWrapper,
  countryList,
  CenterBox,
} from "../shared";
import DonationAmount from "./DonationAmount";
import GiftAidIt from "./GiftAidIt";
import supportedCards from "../../svgs/supported-cards.svg";
import Context from "./Context";
import KeepInTouch from "./KeepInTouch";
import { subscribeToNewsletters } from "../../redux/actions/newsletters";
import formHasErrors from "../../utils/formHasErrors";

const validationSchema = object().shape({
  firstName: string().required("First name not provided."),
  lastName: string().required("Last name not provided."),
  title: string()
    .notOneOf(["Title"], "Title is required.")
    .required("Title not provided."),
  email: string()
    .email("Invalid email provided.")
    .required("Email not provided."),
  mobile: string().required("Mobile not provided."),
  postCode: string().required("Post code not provided."),
  address: string().required("Address not provided."),
  city: string().required("City not provided."),
  county: string().required("County not provided."),
  country: string()
    .notOneOf(["Country"], "Country is not provided")
    .required("Country is required."),
  gdpr: boolean(),
});

const Step1: React.FC<{
  history: { push: Function };
  subscribeToNewsletters: Function;
}> = React.memo(({ history, subscribeToNewsletters }) => {
  const state = useContext(Context);
  const [selectedNewsletters, setSelectedNewsletters] = useState<string[]>([]);

  return (
    <Formik
      onSubmit={(donationData) => {
        state.setState({
          ...state,
          ...donationData,
        });

        subscribeToNewsletters({
          interests: selectedNewsletters,
          email: donationData.email,
          first_name: donationData.firstName,
          last_name: donationData.lastName,
        });

        history.push("/donate/pay");
      }}
      validationSchema={validationSchema}
      initialValues={{
        title: "",
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        postCode: "",
        address: "",
        city: "",
        county: "",
        country: "",
        giftAid: false,
        gdpr: false,
        byPost: false,
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        dirty,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <H1>Donate to the Royal African Society</H1>

          <CenterBox>
            <Separator />
          </CenterBox>

          <Tabs />

          <Steps step={1} maxSteps={3} />

          <DonationAmount />

          <GiftAidIt
            amount={state.amount}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <h2 className="form-title">Your details</h2>

          <FormGroup>
            <InputWrapper>
              <Select
                errored={Boolean(touched.title && errors.title)}
                value={values.title}
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Title *</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
                <option value="Sir">Sir</option>
                <option value="Revd">Revd</option>
                <option value="Dame">Dame</option>
                <option value="Baroness">Baroness</option>
                <option value="Lord">Lord</option>
                <option value="Earl">Earl</option>
                <option value="Rt Hon">Rt Hon</option>
                <option value="Rt Hon Sir">Rt Hon Sir</option>
                <option value="Mx">Mx</option>
              </Select>

              <span className="error">{touched.title && errors.title}</span>
            </InputWrapper>
          </FormGroup>

          <FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.firstName && errors.firstName)}
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First Name *"
                  name="firstName"
                />

                <span className="error">
                  {touched.firstName && errors.firstName}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.lastName && errors.lastName)}
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last Name *"
                  name="lastName"
                />

                <span className="error">
                  {touched.lastName && errors.lastName}
                </span>
              </InputWrapper>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <InputWrapper>
              <Input
                errored={Boolean(touched.email && errors.email)}
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email *"
                name="email"
              />

              <span className="error">{touched.email && errors.email}</span>
            </InputWrapper>
            <InputWrapper>
              <Input
                errored={Boolean(touched.mobile && errors.mobile)}
                type="text"
                value={values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Mobile *"
                name="mobile"
              />

              <span className="error">{touched.mobile && errors.mobile}</span>
            </InputWrapper>
          </FormGroup>

          <FormGroup>
            <Address>
              <Label>Billing Address</Label>
              <FormGroup>
                <InputWrapper>
                  <Input
                    errored={Boolean(touched.postCode && errors.postCode)}
                    value={values.postCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Post Code *"
                    type="text"
                    name="postCode"
                  />

                  <span className="error">
                    {touched.postCode && errors.postCode}
                  </span>
                </InputWrapper>
              </FormGroup>
            </Address>
          </FormGroup>

          <FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.address && errors.address)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.address}
                  placeholder="Address *"
                  name="address"
                />

                <span className="error">
                  {touched.address && errors.address}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.city && errors.city)}
                  type="text"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="City *"
                  name="city"
                />

                <span className="error">{touched.city && errors.city}</span>
              </InputWrapper>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.county && errors.county)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.county}
                  placeholder="County *"
                  name="county"
                />

                <span className="error">{touched.county && errors.county}</span>
              </InputWrapper>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.country && errors.country)}
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  required
                >
                  <option value="Country">Country *</option>

                  {countryList}
                </Select>

                <span className="error">
                  {touched.country && errors.country}
                </span>
              </InputWrapper>
            </FormGroup>
          </FormGroup>

          <KeepInTouch
            selectedNewsletters={selectedNewsletters}
            setField={setFieldValue}
            setSelectedNewsletters={setSelectedNewsletters}
          />

          <Spacer height={7.3} />

          <Button
            secondary
            large
            type="submit"
            disabled={formHasErrors(errors) || isSubmitting || !dirty}
          >
            Next
          </Button>

          <div className="supported-cards">
            <img src={supportedCards} alt="Supported payment options" />
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default connect(
  null,
  {
    subscribeToNewsletters,
  }
)(Step1);
