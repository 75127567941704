import localforage from "localforage";
import addMinutes from "date-fns/add_minutes";

export const persistRegData = async ({
  password,
  confirmPassword,
  ...registrationData
}: any) => {
  await localforage.setItem("registration", registrationData);
};

export const persistSteps = async (
  steps = {
    step1Completed: false,
    step2Completed: false,
    step3Completed: false,
    step4Completed: false
  }
) => {
  await localforage.setItem("steps", steps);
};

export const removeRegData = async () => {
  await localforage.removeItem("registration");
};

export const initializeData = async () => {
  const regData = await localforage.getItem("registration");

  return regData;
};

export const resetTimer = async () => {
  await localforage.setItem("reg-expiry", addMinutes(new Date(), 15));
};
