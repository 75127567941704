import styled from "styled-components";

export const Form = styled.form`
  max-width: 41rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;

  h2 {
    font-size: 3.6rem;
    line-height: 120%;
    font-weight: 300;
  }
`;
