import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import GiftCharge from "./GiftCharge";
import PaypalCharge from "./PaypalCharge";
import CardCharge from "./CardCharge";

const ChargeContainer: React.FC<RouteComponentProps> = props => {
  const subscription = useSelector((state: any) => state.subscription.data);

  if (subscription.subscription_provider === "paypal") {
    return <PaypalCharge {...props} />;
  }

  if (subscription.subscription_provider === "stripe") {
    return <CardCharge {...props} />;
  }

  return <GiftCharge {...props} />;
};

export default ChargeContainer;
