// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import {
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { connect } from "react-redux";

interface Props extends RouteComponentProps<any> {
  title: string;
  description: string;
}

const PageTitle: React.FC<Props> = ({title, description}) => {

  return (
    <Helmet>
      <title>{`${title} - Royal African Society`}</title> 
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:title" content={`${title} - Royal African Society`} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  )
};

export default connect(
  null,
  null,
)(withRouter(PageTitle));