import { toast } from "react-toastify";
import request from "../../utils/request";
import {
  CANCEL_SUB_PENDING,
  CANCEL_SUB_FAILURE,
  CANCEL_SUB_SUCCESS
} from "../constants";

const cancelSub = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: CANCEL_SUB_PENDING
    });

    const { data } = await request.delete("/user-subscription");

    dispatch({
      type: CANCEL_SUB_SUCCESS,
      payload: data.subscription
    });

    toast.success("You have canceled your subscription");
  } catch (err) {
    toast.error("An error occurred");

    dispatch({
      type: CANCEL_SUB_FAILURE
    });
  }
};

export default cancelSub;
