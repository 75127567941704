// @ts-nocheck
import React, { useContext } from "react";
import format from "date-fns/format";
import { useSelector } from "react-redux";
import { Form, H1 } from "./styles";
import { Separator, Steps, Spacer, CenterBox } from "../shared";
import styled, { css } from "styled-components";
import Context from "./Context";

const H2 = styled.h2`
  color: ${({ theme }) => theme.orange};
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 118.5%;
  margin-bottom: 3.8rem;
`;

const Paragraph = styled.p`
  font-size: 1.6rem;
  width: 60.9rem;
  margin: 0 auto;
  line-height: 150%;
  width: 100%;
`;

const DonationDetails = styled.div`
  margin: 0 auto;
  margin-top: 7.8rem;
  ${({ theme }) => css`
    background: ${theme.offWhite2};
    border: ${theme.offWhite};
  `}
  width: 43.7rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  .paragraph {
    font-size: 1.6rem;
    line-height: 150%;
  }

  .title {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.orange};
  }
`;

const Step3: React.FC = React.memo(() => {
  const { amount, donationType, giftAid } = useContext(Context);
  const donation = useSelector(({ donation }: any) => donation.data);

  return (
    <Form>
      <H1>Thank you for your donation</H1>

      <CenterBox>
        <Separator />
      </CenterBox>

      <Steps step={3} maxSteps={3} />

      <H2>We’ve received your donation and we can’t thank you enough!</H2>

      <img src="/images/thankyou.png" alt="thank-you-for-donating" />

      <Spacer height={3.8} />

      <Paragraph>
        We are very grateful for your support. Your donation will have a real
        impact in providing opportunities for people to connect, celebrate and
        engage critically with a wide range of topics and ideas about Africa
        today.
      </Paragraph>

      <Spacer height={3} />

      <Paragraph>
        The gift you have given will help to amplify African voices, culture and
        interests internationally, and to foster mutual understanding and
        dialogue between people in Africa and the UK.
      </Paragraph>

      <Spacer height={3} />

      <Paragraph>Below you’ll find confirmation of your donation:</Paragraph>

      <DonationDetails>
        <p className="title">Your donation details</p>

        <p className="paragraph">
          Date: <strong>{format(Date.now(), "DD/MM/YYYY")}</strong>
        </p>
        <p className="paragraph">
          Frequency of donation: <strong>{donationType}</strong>
        </p>
        <p className="paragraph">
          Donation amount: <strong>£{amount}</strong>
        </p>
        <p className="paragraph">
          Reference number:{" "}
          <strong>{donation && donation.donation.reference}</strong>
        </p>
        <p className="paragraph">
          Gift Aid confirmation: <strong>{giftAid ? "Yes" : "No"}</strong>
        </p>
      </DonationDetails>
    </Form>
  );
});

export default Step3;
