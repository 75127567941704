import styled, { css } from "styled-components";

interface Props {
  errored?: Boolean;
}

const Select = styled.select<Props>`
  width: 100%;
  display: block;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4.8rem;
  outline: transparent;
  font-size: 2rem;
  font-weight: 300;
  padding: 2.2rem 3.7rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.33198L1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 2.7rem top 50%, 0 0;
  color: ${({ theme }) => theme.gray};

  ${({ errored, theme }) =>
    errored &&
    css`
      border: 1px solid ${theme.red};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.gray};
  }
`;

export default Select;
