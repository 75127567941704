import {
  DONATE_SUCCESS,
  DONATE_FAILURE,
  DONATE_PENDING,
  GET_DONATIONS,
  GET_DONATION
} from "../constants";

interface Action {
  type: string;
  payload: any;
}

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

const donation = (state = initialState, action: Action) => {
  switch (action.type) {
    case DONATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case DONATE_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };

    case DONATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true
      };

    default:
      return state;
  }
};

export const donations = (
  state = { current_page: 0, data: null, total: 0, last_page: 0 },
  action: Action
) => {
  switch (action.type) {
    case GET_DONATIONS:
      return {
        current_page: action.payload.current_page,
        data: action.payload.data,
        total: action.payload.total,
        last_page: action.payload.last_page
      };

    default:
      return state;
  }
};

export const adminDonation = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_DONATION:
      return {
        ...state,
        data: action.payload
      };

    default:
      return state;
  }
};

export default donation;
