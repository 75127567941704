import {
  GET_NEWSLETTERS_FAILURE,
  GET_NEWSLETTERS_PENDING,
  GET_NEWSLETTERS_SUCCESS
} from '../constants';

interface Action {
  type: string,
  payload: any,
}

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null,
};

export const newsletters = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_NEWSLETTERS_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      }

    case GET_NEWSLETTERS_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false,
      }

    case GET_NEWSLETTERS_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false,
      }

    default:
      return state;
  }
}