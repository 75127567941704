import React from "react";
import { NewsGrid, SubscribersCard } from "../../Dashboard/styles";
import ViewAll from "../../Dashboard/ViewAll";
import { ReactComponent as RightIcon } from "../../../svgs/caret-right.svg";
import { Summary, DonationCardContent } from "./style";

const Giftings: React.FC<{ count: number }> = ({ count }) => {
  return (
    <NewsGrid>
      <SubscribersCard>
        <DonationCardContent tertiary className="subscribersContent">
          <Summary>
            <p>{count}</p>
          </Summary>
          <p className="title">Gifts</p>

          <Summary hide>
            <p>
              {count}
              <br />
              <span>Total</span>
            </p>
          </Summary>

          <ViewAll to="/admin/dashboard/gifts" className="events">
            View all <RightIcon />
          </ViewAll>
        </DonationCardContent>
      </SubscribersCard>
    </NewsGrid>
  );
};

export default Giftings;
