// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";

const interests = ["Business", "Politics", "Arts & Culture", "Academia"];

const MultiSelect: React.FC<{
  initialValue?: string;
  onChange: (option: string[]) => void;
}> = ({ onChange, initialValue = "Interests" }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [dropdownState, setDropdownState] = useState(false);

  const defaultSelected = initialValue;

  const handleChange = ({ target: { name, checked } }: any) => {
    if (checked) {
      const options = [...selectedOptions, name];
      setSelectedOptions(options);
      onChange(options);
    } else {
      const options = selectedOptions.filter(option => option !== name);
      setSelectedOptions(options);
      onChange(options);
    }
  };

  return (
    <Select>
      <span
        className="selected"
        onMouseOver={() => {
          setDropdownState(true);
        }}
        onClick={() => {
          setDropdownState(!dropdownState);
        }}
      >
        {selectedOptions.length > 0
          ? selectedOptions.join(", ")
          : defaultSelected}
      </span>
      {dropdownState && (
        <Options
          onMouseLeave={() => {
            setDropdownState(false);
          }}
        >
          {interests.map((interest: string) => (
            <Option key={interest}>
              <Checkbox
                checked={selectedOptions.includes(interest)}
                label={interest}
                name={interest}
                onChange={handleChange}
              />
            </Option>
          ))}
        </Options>
      )}
    </Select>
  );
};

const Select = styled.div`
  background: transparent;
  width: 100%;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.33198L1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 2.7rem top 50%, 0 0;

  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4.8rem;
  line-height: 1.5rem;
  outline: transparent;
  font-size: 2rem;
  position: relative;
  text-align: left;

  .selected {
    width: 100%;
    display: block;
    padding: 2.2rem 3.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    color: ${({ theme }) => theme.gray};
  }
`;

const Options = styled.div`
  position: absolute;
  top: 7rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 1rem;
  z-index: 100;
  padding: 2rem;
  background: ${({ theme }) => theme.white};
`;

const Option = styled.span`
  display: block;
  padding: 2px 0;
  font-size: 1.4rem;
`;

export default MultiSelect;
