import React from "react";
import styled from "styled-components";

const Group = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
// @ts-ignore
const FormGroup: React.FC = ({ children }) => <Group>{children}</Group>;

export default FormGroup;
