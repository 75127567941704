import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import user from "./user";
import donation, { donations, adminDonation } from "./donation";
import subscription from "./subscription";
import addresses from "./addresses";
import events from "./events";
import singleEvent from "./singleEvent";
import { discounts, singleDiscount } from "./discount";
import { LOGOUT } from "../constants";
import { singleNews, news } from "./news";
import registrationSteps from "./regSteps";
import customPlan from "./customPlan";
import { newsletters } from "./newsletters";
import { footer } from "./footer";
import { faqs } from "./faq";
import { admin } from "./admin";
import { subscribers, subscriber } from "./subscribers";
import { gifts, gift } from "./gifts";

const appReducer = combineReducers({
  user,
  events,
  donation,
  addresses,
  subscription,
  singleEvent,
  singleNews,
  news,
  discounts,
  singleDiscount,
  customPlan,
  registrationSteps,
  newsletters,
  footer,
  faqs,
  admin,
  subscribers,
  gifts,
  donations,
  subscriber,
  gift,
  adminDonation,
  loadingBar: loadingBarReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
