import axios from "axios";
import { wordPressAPI } from "../../config";
import {
  GET_FOOTER_FAILURE,
  GET_FOOTER_PENDING,
  GET_FOOTER_SUCCESS
} from "../constants";

export const getFooter = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_FOOTER_PENDING
    });

    const { data } = await axios.get(`${wordPressAPI}/footer`);

    dispatch({
      type: GET_FOOTER_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: GET_FOOTER_FAILURE
    });
  }
};
