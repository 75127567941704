import React from "react";
import { NewsGrid, SubscribersCard } from "../../Dashboard/styles";
import ViewAll from "../../Dashboard/ViewAll";
import { ReactComponent as RightIcon } from "../../../svgs/caret-right.svg";
import { Summary, DonationCardContent } from "./style";

const Donations: React.FC<{ count: number }> = ({ count }) => {
  return (
    <NewsGrid>
      <SubscribersCard>
        <DonationCardContent secondary className="subscribersContent">
          <Summary>
            <p>{count}</p>
          </Summary>
          <p className="title">Donations</p>

          <Summary hide>
            <p>
              {0}
              <br />
              <span>Active</span>
            </p>
          </Summary>

          <ViewAll to="/admin/dashboard/donations" className="events">
            View all <RightIcon />
          </ViewAll>
        </DonationCardContent>
      </SubscribersCard>
    </NewsGrid>
  );
};

export default Donations;
