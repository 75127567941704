import { useState } from "react";

const useLoading = () => {
  const [isLoading, setState] = useState(false);

  const load = (promise: Promise<any>) => {
    setState(true);

    promise.finally(() => {
      setState(false);
    });
  };

  return [isLoading, load] as const;
};

export default useLoading;
