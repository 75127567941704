import React from "react";
import { H1, HR } from "../../Dashboard/styles";
import { PageContainer, Spacer } from "../../shared";
import Subscribers from "./Subscribers";
import Giftings from "./Giftings";
import Donations from "./Donations";
import Flex from "./shared/Flex";
import { useCountDonations, useCountGifts, useCountSubscriber } from "./hooks";

const Home: React.FC = () => {
  const donationCount = useCountDonations();
  const giftCount = useCountGifts();
  const subscriberCount = useCountSubscriber();

  return (
    <PageContainer>
      <Spacer height={8.9} />
      <H1>
        Royal African Society
        <br />
        Admin Dashboard
      </H1>

      <HR />

      <Spacer height={7.5} />
      <Flex>
        <Subscribers count={subscriberCount} />
        <Donations count={donationCount} />
        <Giftings count={giftCount} />
      </Flex>

      <Spacer height={7.5} />
    </PageContainer>
  );
};

export default Home;
