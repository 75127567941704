// @ts-nocheck
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface BurgerProps {
  mobileNavState: boolean;
  setMobileNavState: Function;
}

const StyledBurger = styled.div<BurgerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 4rem;
    height: 0.4rem;
    background: ${({ theme, mobileNavState }) =>
      mobileNavState ? theme.white : theme.black};
    border-radius: 0.1rem;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 0.1rem;
    margin-bottom: 0.4rem;

    :first-child {
      transform: ${({ mobileNavState }) =>
        mobileNavState ? "rotate(45deg)  translate(0px, 0px)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ mobileNavState }) => (mobileNavState ? "0" : "1")};
      transform: ${({ mobileNavState }) =>
        mobileNavState ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ mobileNavState }) =>
        mobileNavState ? "rotate(-45deg) translate(-8px,7px)" : "rotate(0)"};
    }
  }
`;

interface Props extends RouteComponentProps<any> {
  setMobileNavState: Function;
  mobileNavState: boolean;
}

const Burger: React.FC<Props> = ({ mobileNavState, setMobileNavState }) => {
  return (
    <StyledBurger
      mobileNavState={mobileNavState}
      setMobileNavState={setMobileNavState}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default connect(null, null)(withRouter(Burger));
