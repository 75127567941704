// @ts-nocheck
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Carousel from "nuka-carousel";
import {
  NewsGrid,
  NewsTitle,
  IndividualNewsContainer,
  NewsContent
} from "./styles";
import ViewAll from "./ViewAll";
import { ReactComponent as RightIcon } from "../../svgs/caret-right.svg";
import { getNews } from "../../redux/actions/news";
import { Underline } from "./AllNews/styles";
import { numberDiscountsItems, initialPage } from "../../config";

const News: React.FC<{ getNews: Function }> = ({ getNews }) => {
  useEffect(() => {
    getNews({
      perPage: numberDiscountsItems,
      page: initialPage
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const news = useSelector(({ news }: any) => news.data);

  return (
    <NewsGrid>
      <NewsTitle>
        <div>
          <p className="title">Latest News</p>

          <ViewAll to="/dashboard/news">
            View all <RightIcon />
          </ViewAll>
        </div>
      </NewsTitle>
      <IndividualNewsContainer>
        <div className="show-mobile">
          <Carousel
            withoutControls={false}
            slidesToShow={1}
            enableKeyboardControls={true}
            cellSpacing={10}
            renderBottomLeftControls={() => <></>}
            renderBottomRightControls={() => <></>}
            renderTopCenterControls={() => <></>}
            renderCenterLeftControls={() => <> </>}
            renderCenterRightControls={() => <></>}
          >
            {news &&
              news.data.slice(0, 3).map((story: any) => (
                <NewsContent
                  key={story.id}
                  featuredImage={story.featured_img_src}
                  className="newsContent"
                >
                  <Link to={`/dashboard/news/${story.slug}`}>
                    <div className="headimage">
                      <div></div>
                    </div>
                  </Link>
                  <Link
                    className="headline"
                    to={`/dashboard/news/${story.slug}`}
                  >
                    {story.title.length < 80
                      ? story.title
                      : story.title.substr(0, 80) + "..."}
                  </Link>

                  <Underline />
                </NewsContent>
              ))}
          </Carousel>
        </div>
        {news &&
          news.data.slice(0, 3).map((story: any) => (
            <NewsContent
              key={story.id}
              featuredImage={story.featured_img_src}
              className="newsContent show-desktop"
            >
              <Link to={`/dashboard/news/${story.slug}`}>
                <div className="headimage">
                  <div></div>
                </div>
              </Link>
              <Link className="headline" to={`/dashboard/news/${story.slug}`}>
                {story.title.length < 45
                  ? story.title
                  : story.title.substr(0, 45) + "..."}
              </Link>

              <Underline />
            </NewsContent>
          ))}
      </IndividualNewsContainer>
    </NewsGrid>
  );
};

export default connect(null, { getNews })(News);
