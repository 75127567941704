import { GET_GIFTS, GET_GIFT } from "../constants";

interface Action {
  type: string;
  payload: any;
}

interface State {
  current_page: number;
  data: any;
  total: number;
  last_page: number;
}

const initialState = {
  current_page: 0,
  data: null,
  total: 0,
  last_page: 0
};

export const gifts = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_GIFTS:
      return {
        current_page: action.payload.current_page,
        data: action.payload.data,
        total: action.payload.total,
        last_page: action.payload.last_page
      };

    default:
      return state;
  }
};

export const gift = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_GIFT:
      return {
        ...state,
        data: action.payload
      };

    default:
      return state;
  }
};
