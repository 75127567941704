import styled from "styled-components";

const ViewMore = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.orange};
  font-size: 2.4rem;
  line-height: 107.5%;
  cursor: pointer;
  padding-bottom: 8.2rem;

  svg {
    height: 14px;
    width: 9px;
    margin-left: 2rem;
  }
`;

export default ViewMore;
