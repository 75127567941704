// @ts-nocheck
import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { Input as Field, FormGroup } from "../shared";
import Context from "./Context";
import { formatToGBP } from "../../utils/currency";

interface AmountButtonProps {
  readonly active: boolean;
  readonly theme: object;
}

const DonationAmountWrapper = styled.div`
  .title {
    font-size: 2.4rem;
    margin: 0 auto;
    margin-bottom: 4.4rem;
    color: ${({ theme }) => theme.greenishBlack};
  }

  margin-bottom: 9.1rem;
`;

const AmountButton = styled.button<AmountButtonProps>`
  width: 13rem;
  height: 6rem;
  border-radius: 4.8rem;
  font-size: 2rem;
  line-height: 1.5rem;
  outline: transparent;
  cursor: pointer;
  margin-right: 0.462rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 1.5rem;
  }

  ${({ active, theme }) =>
    active
      ? css`
          background: ${theme.orange};
          border: 1px solid ${theme.orange};
          color: ${theme.white};
        `
      : css`
          background: ${theme.offWhite2};
          border: 1px solid ${theme.gray2};
        `};
`;

const InputWrapper = styled.div`
  display: inline-block;
  width: 16.4rem;
`;

const Input = styled(Field)`
  height: 6rem;
`;

const DonationAmount: React.FC = React.memo(() => {
  const state = useContext(Context);
  const { amount, setState } = state;
  const [customAmount, setCustomAmount] = useState();
  const defaultAmounts = [10, 20, 30, 50];

  const safeSetState = (amount: number) => {
    setState({
      ...state,
      amount
    });
  };

  return (
    <DonationAmountWrapper>
      <p className="title">Please select the amount you would like to donate</p>

      <FormGroup>
        {defaultAmounts.map(defaultAmount => (
          <AmountButton
            type="button"
            key={`${defaultAmount}plan`}
            onClick={() => {
              safeSetState(defaultAmount);
              setCustomAmount("");
            }}
            active={amount === defaultAmount}
          >
            {formatToGBP(defaultAmount, 0)}
          </AmountButton>
        ))}

        <InputWrapper>
          <Input
            type="number"
            placeholder="Other..."
            min={0}
            name="amount"
            value={customAmount}
            onChange={({ target: { value } }) => {
              setCustomAmount(parseFloat(value));

              if (!value) {
                safeSetState(10);
              }
            }}
            onBlur={({ target: { value } }) => {
              if (value) {
                safeSetState(parseFloat(value));
              }
            }}
          />
        </InputWrapper>
      </FormGroup>
    </DonationAmountWrapper>
  );
});

export default DonationAmount;
