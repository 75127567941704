import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Check } from "./check.svg";

interface InputProps {
  readonly large?: boolean;
  readonly small?: boolean;
}

const Label = styled.label<InputProps>`
  display: inline-flex;
  cursor: pointer;
  line-height: 1;
  user-select: none;
  align-items: center;

  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    :checked ~ .checkmark {
      border: 1px solid #5c6ac4;

      svg {
        display: inline;
      }
    }
  }

  ${({ small }) =>
    small &&
    css`
      .label {
        margin-left: 1.4rem;
      }

      .checkmark {
        height: 0.9rem;
        width: 1.4rem;
        border: 1px solid #bdbdbd;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        svg {
          height: 1.3rem;
          width: 1.3rem;
          display: none;
        }
      }
    `}

  ${({ large }) =>
    large &&
    css`
      .label {
        margin-left: 1.0rem;
      }

      .checkmark {
        height: 1.8rem;
        width: 1.8rem;
        border: 1px solid #bdbdbd;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        svg {
          height: 1.7rem;
          width: 1.7rem;
          display: none;
        }
      }
    `}
`;

Label.defaultProps = {
  small: true
};

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  readonly label?: JSX.Element | ReactNode;
  readonly large?: boolean;
  readonly small?: boolean;
  readonly checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  large,
  small,
  ...props
}) => (
  <Label large small>
    <input className="input" type="checkbox" {...props} />

    <span className="checkmark">
      <Check />
    </span>

    <span className="label">{label}</span>
  </Label>
);

Checkbox.defaultProps = {
  label: <span />
};

export default Checkbox;
