import styled, { css } from "styled-components";

const EventContent = styled.div<{ featuredImage: string }>`
  word-wrap: break-word;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  ${({ featuredImage }) => css`
    background: linear-gradient(0deg, rgba(1,1,1,.7), rgba(1,1,1,.7)),
      url('${featuredImage}');
    background-position: center;
    background-size: cover;
  `}
  padding: 5.6rem 4.9rem 6.2rem;

  &:hover {
    ${({ featuredImage }) => css`
      background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url('${featuredImage}');
      background-position: center;
      background-size: cover;
    `}
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 107.5%;
    font-weight: 300;
  }

  .title {
    font-size: 3.6rem;
    line-height: 107.5%;
    font-weight: 300;
    margin-bottom: 2.2rem;
    margin-top: 2.6rem;
    text-decoration: none;
    color: ${({ theme }) => theme.white};
    word-break: break-word;

    :hover {
      color: ${({ theme }) => theme.orange};
    }
  }
`;

export default EventContent;
