// @ts-nocheck
import React, { Fragment, useState, useContext } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ThemeContext } from "styled-components";
import { CreditCardContainer } from "./styles";
import { Button, Spacer } from "../shared";
import supportPaymentOptions from "../../svgs/supported-cards.svg";
import { parseQueryString } from "../../utils/queryString";
import { useCreateGiftWithStripe } from "./hooks";

interface Props extends RouteComponentProps {
  [key: string]: any;
}

const CheckoutForm: React.FC<Props> = ({ state, stripe, location, goNext }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, createGift]: any = useCreateGiftWithStripe(goNext);
  const [checkoutLoadingState, setCheckoutLoadingState] = useState(false);
  const theme = useContext(ThemeContext);

  const submit = async () => {
    setCheckoutLoadingState(true);
    const name = `${state.firstName} ${state.lastName}`;
    const { token } = await stripe.createToken({ name });
    const { plan } = parseQueryString(location.search);

    const giftData = {
      plan,
      ...state,
      paymentSource: token.id
    };

    await createGift(giftData);

    setCheckoutLoadingState(false);
  };

  return (
    <Fragment>
      <CreditCardContainer>
        <CardElement
          style={{
            base: {
              fontSize: "14px",
              "::placeholder": {
                color: "#bdbdbd"
              }
            }
          }}
        />
      </CreditCardContainer>

      <Spacer height={3.1} />

      <Button
        type="button"
        onClick={submit}
        large
        disabled={checkoutLoadingState}
      >
        {checkoutLoadingState ? (
          <Loader type="Oval" color={theme.white} height={20} width={20} />
        ) : (
          "Pay"
        )}
      </Button>

      <Spacer height={5.7} />

      <img src={supportPaymentOptions} alt="Support payment options" />
    </Fragment>
  );
};

const TransformedCheckoutForm = injectStripe(withRouter(CheckoutForm));

export default TransformedCheckoutForm;
