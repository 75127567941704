import { toast } from "react-toastify";
import request from "../../utils/request";
import {
  UPGRADE_PLAN_SUCCESS,
  UPGRADE_PLAN_FAILURE,
  UPGRADE_PLAN_PENDING,
  RENEW_PLAN_FAILURE,
  RENEW_PLAN_PENDING,
  RENEW_PLAN_SUCCESS
} from "../constants";
import keysToSnakeCase from "../../utils/keysToSnakeCase";

export const upgradePlan = (
  { plan }: { plan: string },
  redirectTo: Function
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: UPGRADE_PLAN_PENDING
    });

    const { data } = await request.put("/user-subscription", { plan });

    dispatch({
      type: UPGRADE_PLAN_SUCCESS,
      payload: data
    });

    toast.success("Subscription upgraded successfully!");

    redirectTo("/dashboard/account");
  } catch (err) {
    toast.error("An error occurred");

    dispatch({
      type: UPGRADE_PLAN_FAILURE
    });
  }
};

export const renewPlan = (
  payload: { [key: string]: string },
  redirectTo: Function
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: RENEW_PLAN_PENDING
    });

    const { data } = await request.post(
      "/user-subscription/renew",
      keysToSnakeCase(payload)
    );

    dispatch({
      type: RENEW_PLAN_SUCCESS,
      payload: data.subscription
    });

    toast.success("You have renewed your subscription successfully.");

    redirectTo("/dashboard/account");
  } catch (err) {
    toast.error("You can't renew now.");

    dispatch({
      type: RENEW_PLAN_FAILURE
    });
  }
};
