// @ts-nocheck
import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Case from "case";
import format from "date-fns/format";
import { PageContainer, Spacer, CenterBox, PageLoader } from "../../../shared";
import { PageTitle } from "../../../shared/PageTitle";
import { H1, HR } from "../../../Dashboard/styles";
import { GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { useGetGift } from "./hooks";
import useLoading from "../../../../hooks/useLoading";
import Flex from "../shared/Flex";
import { PersonalInforamtionContainer } from "../AllSubscribers/styles";
import { formatToGBP } from "../../../../utils/currency";

const BackToGifts = styled(GoBack)`
  width: 100%;
  height: 12.2rem;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.offWhite2};
`;

const SingleGift: React.FC<RouteComponentProps> = ({ match: { params } }) => {
  const [gift, getGift] = useGetGift();
  const [isLoading, load] = useLoading();
  const { giftID }: any = params;

  useEffect(() => {
    load(getGift(giftID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftID]);

  return (
    <>
      {isLoading && <PageLoader />}

      {!isLoading && gift.data && (
        <>
          <PageContainer>
            <PageTitle
              title={`${gift.data.title} ${gift.data.first_name} ${gift.data.last_name}'s Gift Details`}
              description={`${gift.data.title} ${gift.data.first_name} ${gift.data.last_name}'s Gift Details  on the Royal African Society`}
            />
            <Spacer height={8.9} />

            <H1>
              {`${gift.data.title} ${gift.data.first_name}`} <br />{" "}
              {`${gift.data.last_name}'s Gift Details`}
            </H1>

            <HR />

            <Spacer height={7.4} />
            <PersonalInforamtionContainer>
              <Flex>
                <div>
                  <p className="title">Fullname:</p>
                  <div className="panel">
                    {Case.capital(
                      gift.data.title +
                        gift.data.first_name +
                        gift.data.last_name
                    )}{" "}
                  </div>
                  <p className="title">Email:</p>
                  <div className="panel">{gift.data.email} </div>
                  <p className="title">Mobile:</p>
                  <div className="panel">{gift.data.mobile} </div>
                  <p className="title">Plan:</p>
                  <div className="panel">{Case.capital(gift.data.plan)} </div>
                  <p className="title">Amount:</p>
                  <div className="panel">{formatToGBP(gift.data.amount)} </div>
                </div>
                <div>
                  <p className="title">Post Code:</p>
                  <div className="panel">{gift.data.post_code} </div>
                  <p className="title">Address:</p>
                  <div className="panel">{gift.data.address} </div>

                  <p className="title">City:</p>
                  <div className="panel">{gift.data.city} </div>

                  <p className="title">Area:</p>
                  <div className="panel">{gift.data.county} </div>
                  <p className="title">Nationality:</p>
                  <div className="panel">{gift.data.country} </div>
                  <p className="title">Date:</p>
                  <div className="panel">
                    {format(gift.data.created_at, "DD/MM/YYYY")}{" "}
                  </div>
                </div>
              </Flex>
            </PersonalInforamtionContainer>
          </PageContainer>
        </>
      )}
      <Spacer height={13.5} />

      <BackToGifts>
        <CenterBox>
          <Link to="/admin/dashboard/gifts">
            <BackIcon />
            Go Back
          </Link>
        </CenterBox>
      </BackToGifts>
    </>
  );
};

export default SingleGift;
