// @ts-nocheck
import React, { useContext } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Form, H1 } from "./styles";
import CheckoutForm from "./CheckoutForm";
import { formatToGBP } from "../../utils/currency";
import { Spacer, Steps, Separator, CenterBox } from "../shared";
import { stripeKey } from "../../config";
import Context from "./Context";
import styled from "styled-components";

const P = styled.h4`
text-align: center;
`;


const Step2: React.FC = React.memo(() => {
  const { donationType, amount } = useContext(Context);

  return (
    <>
      <Form>
        <H1>Enter Your Card Details</H1>

        <CenterBox>
          <Separator />
        </CenterBox>

        <p className="donate-message">
          You are making a <span>{donationType}</span> donation of{" "}
          <span>{formatToGBP(amount, 0)}</span>
        </p>

        <Spacer height={6.6} />

        <Steps step={2} maxSteps={3} />

        <h2 className="form-title">Payment Details</h2>

        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <CheckoutForm />
          </Elements>
        </StripeProvider>
        <br></br>
        <br></br>
        <P>Please only click the Donate button once and don't go back or refresh the page as you may be charged twice.</P>
      </Form>
    </>
  );
});

export default Step2;
