import { useState, useEffect } from "react";
import axios from "axios";
import { apiBaseURL } from "../../config";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER } from "../../redux/constants";

export function useAddCard() {
  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  return {
    modalState,
    toggleModal,
  };
}

export function useNewsletter() {
  const [newsletters, setNewsletters] = useState<
    | {
        [key: string]: string;
      }[]
    | null
  >(null);
  const [subscribed, setSubscribed] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [newsletterLoading, setNewsletterLoading] = useState(true);

  const newslettersReq = axios.get(`${apiBaseURL}/mailing-lists`);
  const subscribedReq = request.get(`${apiBaseURL}/user/mailing-list`);
  useEffect(() => {
    Promise.all([newslettersReq, subscribedReq]).then(
      ([{ data: nl }, { data: subbed }]) => {
        setSubscribed(subbed.subscribed_list);
        setNewsletters(nl.mailing_lists);

        setNewsletterLoading(false);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscribe = async (payload: any, callback: Function) => {
    try {
      const { data } = await request.put(`${apiBaseURL}/user/mailing-list`, {
        interests: payload,
      });

      setSubscribed(data.interests);

      callback();

      toast.success("Preference submitted!");
    } catch (err) {
      toast.error("An error occurred!");
    }
  };

  return {
    newsletterLoading,
    newsletters,
    subscribed,
    subscribe,
  };
}


export const useGetProfile = () => {
  const dispatch = useDispatch();
  const user: any = useSelector((state: any) => state.user);

  const getUser = async (id: number) => {
    try {
      const { data } = await request.get(`/subscribers/${id}`);

      dispatch({
        type: GET_USER,
        payload: data.user
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [user, getUser] as const;
};