// @ts-nocheck
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Steps,
  Separator,
  Spacer,
  CenterBox,
  ScrollToTopOnMount
} from "../shared";
import { formatToGBP } from "../../utils/currency";
import { Form, H1 } from "./styles";
import { stripeKey } from "../../config";
import CheckoutForm from "./CheckoutForm";

const amounts: { [key: string]: number } = {
  student: 40,
  individual: 85,
  friend: 120,
  lifetime: 1500,
  corporate: 2400
};

interface Props {
  state: { [key: string]: string | boolean };
  plan: string;
  goNext: Function;
}

const Step2: React.FC<Props> = ({ state, plan, goNext }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Form>
        <H1>
          Enter Your <br />
          Card Details
        </H1>

        <CenterBox>
          <Separator />
        </CenterBox>

        <p className="sub-confirmation">
          You are paying <span>{formatToGBP(Number(amounts[plan]), 0)}</span>{" "}
          for the <span>{plan}</span> plan membership as a gift.
        </p>


        <Spacer height={7} />

        <Steps maxSteps={3} step={2} />

        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <CheckoutForm state={state} goNext={goNext} plan={plan} />
          </Elements>
        </StripeProvider>
      </Form>
    </>
  );
};

export default Step2;
