import {
  GET_ALL_NEWS_FAILURE,
  GET_ALL_NEWS_PENDING,
  GET_ALL_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAILURE,
  GET_SINGLE_NEWS_PENDING,
  GET_SINGLE_NEWS_SUCCESS,
  GET_MORE_NEWS
} from "../constants";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

interface IState {
  loading: boolean;
  success: boolean;
  failed: boolean;
  data: any;
}

interface IAction {
  type: string;
  payload: any;
}

export const news = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case GET_ALL_NEWS_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case GET_MORE_NEWS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          data: [...state.data.data, ...action.payload.data]
        }
      };

    case GET_ALL_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false
      };

    case GET_ALL_NEWS_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false
      };

    default:
      return state;
  }
};

export const singleNews = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case GET_SINGLE_NEWS_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case GET_SINGLE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false
      };

    case GET_SINGLE_NEWS_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false
      };

    default:
      return state;
  }
};
