import axios from "axios";
import {
  GET_SINGLE_DISCOUNT_FAILURE,
  GET_SINGLE_DISCOUNT_PENDING,
  GET_SINGLE_DISCOUNT_SUCCESS,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_PENDING,
  GET_DISCOUNTS_FAILURE,
  GET_MORE_DISCOUNTS
} from "../constants";
import { wordPressAPI } from "../../config";
import { toast } from "react-toastify";

interface Params {
  perPage: number;
  page: number;
  plan: string;
}

export const getDiscounts = (params: Params) => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_DISCOUNTS_PENDING
    });

    const { data } = await axios.get(
      `${wordPressAPI}/discounts?page=${params.page}&per_page=${params.perPage}&plan=${params.plan}`
    );

    dispatch({
      type: GET_DISCOUNTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: GET_DISCOUNTS_FAILURE
    });
  }
};

export const getMoreDiscounts = (params: Params) => async (
  dispatch: Function
) => {
  try {
    const { data } = await axios.get(
      `${wordPressAPI}/discounts?page=${params.page}&per_page=${params.perPage}`
    );

    dispatch({
      type: GET_MORE_DISCOUNTS,
      payload: data
    });
  } catch (err) {
    toast.error("An error occurred!");
  }
};

export const getSingleDiscount = (discountID: number) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: GET_SINGLE_DISCOUNT_PENDING
    });

    const { data } = await axios.get(`${wordPressAPI}/discounts/${discountID}`);

    dispatch({
      type: GET_SINGLE_DISCOUNT_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: GET_SINGLE_DISCOUNT_FAILURE
    });
  }
};
