import { useDispatch, useSelector } from "react-redux";
import localforage from "localforage";
import axios from "axios";
import { toast } from "react-toastify";
import {
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_PENDING,
  ADMIN_LOGIN_SUCCESS
} from "../../../redux/constants";
import { apiBaseURL } from "../../../config";

export const useLogin = () => {
  const dispatch = useDispatch();
  const adminState: any = useSelector(({ admin }: any) => admin);

  const login = async (
    creds: { [key: string]: string },
    callback: Function
  ) => {
    dispatch({
      type: ADMIN_LOGIN_PENDING
    });

    try {
      const { data } = await axios.post(`${apiBaseURL}/admin/login`, creds);

      await localforage.setItem("admin_token", data.token);

      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: data.admin
      });

      callback();
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error("An error occurred");
      }

      dispatch({
        type: ADMIN_LOGIN_FAILURE
      });
    }
  };

  return [adminState, login];
};

export const useRedirect = (redirect: Function) => {
  const isAuthed = useSelector((state: any) => state.admin.data);

  if (isAuthed) {
    redirect("/admin/dashboard");
  }
};
