import {
  GET_SUBSCRIBERS,
  GET_SUBSCRIBER,
  CREATE_SUBSCRIBER,
  DEACTIVATE_SUBSCRIBER,
  ACTIVATE_SUBSCRIBER
} from "../constants";

interface Action {
  type: string;
  payload: any;
}

interface AllSubscribersState {
  current_page: number;
  data: any;
  total: number;
  last_page: number;
}

const initialState = {
  current_page: 0,
  data: null,
  total: 0,
  last_page: 0
};

export const subscribers = (
  state: AllSubscribersState = initialState,
  action: Action
) => {
  switch (action.type) {
    case GET_SUBSCRIBERS:
    case CREATE_SUBSCRIBER:
      return {
        current_page: action.payload.current_page,
        data: action.payload.data,
        total: action.payload.total,
        last_page: action.payload.last_page
      };

    default:
      return state;
  }
};

export const subscriber = (
  state = {
    loading: false,
    failed: false,
    success: false,
    data: null
  },
  action: Action
) => {
  switch (action.type) {
    case GET_SUBSCRIBER:
    case DEACTIVATE_SUBSCRIBER:
    case ACTIVATE_SUBSCRIBER:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    default:
      return state;
  }
};
