// @ts-nocheck
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { PageContainer, Spacer, Controls } from "../../shared";
import { H1, HR } from "../styles";
import { ReactComponent as BackIcon } from "../../../svgs/caret-left.svg";
import CalendarView from "./CalendarView";
import GridView from "./GridView";
import { GoBack } from "../styles";
import { PageTitle } from "../../shared/PageTitle";
import { useGetEventCategories } from "./hooks";
import { numberNewsItems } from "../../../config";

const AllEvents: React.FC = () => {
  const [view, setView] = useState("grid");
  const [controls, setControls] = useState<{
    date: number | undefined;
    perPage: number;
    filter: string | number;
    page: number;
  }>({
    date: undefined,
    perPage: numberNewsItems,
    filter: "",
    page: 1
  });

  const categories = useGetEventCategories();

  const setDate = (date: number) => {
    setControls({
      ...controls,
      date
    });
  };

  const setFilter = (filter: string | number) => {
    setControls({
      ...controls,
      filter
    });
  };

  const memoizedControls = useMemo(() => controls, [controls]);

  return (
    <>
      <PageTitle
        title="Events"
        description="Latest events on the Royal African Society"
      />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>Events</H1>

        <HR />

        <GoBack>
          <Link to="/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={8.94} />

        <Controls
          view={view}
          setView={setView}
          setFilter={setFilter}
          setDate={setDate}
          categories={categories}
          type="events"
        />

        <Spacer height={6.4} />

        {view === "grid" ? (
          <GridView controls={memoizedControls} />
        ) : (
          <CalendarView controls={memoizedControls} />
        )}

        <Spacer height={14.9} />
      </PageContainer>
    </>
  );
};

export default AllEvents;
