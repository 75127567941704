// @ts-nocheck
import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import { connect, useSelector } from "react-redux";
import Case from "case";
import { Route } from "react-router-dom";
import {
  PrimaryNav,
  Footer,
  Modal,
  CenterBox,
  Spacer,
  Separator,
  IdleTimer
} from "../shared";
import Home from "./Home";
import Account from "./Account";
import Profile from "./Profile";
import FAQs from "./FAQs";
import Plans from "./upgradePlan/Plans";
import ChargeContainer from "./upgradePlan/ChargeContainer";
import AllEvents from "./AllEvents";
import AllPlans from "./Plans";
import SingleEvent from "./AllEvents/SingleEvent";
import AllNews from "./AllNews";
import SingleNews from "./AllNews/SingleNews";
import AllDiscounts from "./AllDiscounts";
import AfricanAffairs from "./AfricanAffairs";
import SubGate from "./SubGate";
import getSub from "../../redux/actions/getSub";
import updateSub from "../../redux/actions/updateSub";
import { ModalWrapper, PanelButton } from "./styles";
import { ReactComponent as CaretRight } from "../../svgs/caret-right.svg";
import { PageTitle } from "../shared/PageTitle";

const Dashboard: React.FC<{
  getSub: Function;
  updateSub: Function;
}> = ({ updateSub }) => {
  const subscription = useSelector((state: any) => state.subscription);

  const [panelState, setPanelState] = useState(
    Boolean(subscription.data.is_new)
  );

  const togglePanel = () => {
    setPanelState(!panelState);
    updateSub();
  };

  return (
    // 1 minute * 15
    <IdleTimer timeout={60000 * 15}>
      <PageTitle
        title="Dashboard"
        description="Welcome to your Royal African Society Dashboard"
      />
      <PrimaryNav />
      <SubGate path="/dashboard" component={Home} exact />
      <SubGate path="/dashboard/account" component={Account} exact />
      <SubGate path="/dashboard/profile" component={Profile} exact />
      <Route path="/dashboard/faqs" component={FAQs} exact />
      <SubGate path="/dashboard/account/upgrade-plan" component={Plans} exact />
      <SubGate
        path="/dashboard/account/upgrade-plan/pay"
        component={ChargeContainer}
        exact
      />
      <Route
        path="/dashboard/account/renew-plan/pay"
        component={ChargeContainer}
        exact
      />
      {
        subscription.data.plan !== "artAndCulture" && (
          <SubGate
            path="/dashboard/african-affairs"
            component={AfricanAffairs}
            exact
          />
        )
      }
      <SubGate path="/dashboard/events" component={AllEvents} exact />
      <SubGate
        path="/dashboard/events/:eventID"
        component={SingleEvent}
        exact
      />
      <SubGate path="/dashboard/news" component={AllNews} exact />
      <SubGate path="/dashboard/news/:newsID" component={SingleNews} exact />
      <SubGate path="/dashboard/discounts" component={AllDiscounts} exact />
      <SubGate
        path="/dashboard/account/renew-plan"
        component={AllPlans}
        exact
      />

      <Footer />

      <Modal
        title="Welcome"
        modalActive={panelState}
        toggleModal={togglePanel}
        type="panel"
      >
        <ModalWrapper>
          <h1>
            Welcome to the Royal African
            <br />
            Society Member Portal
          </h1>
          <Separator />
          <div className="text">
            {subscription.data.subscription_provider === "gift" ? (
              <>
                Your <strong>{Case.capital(subscription.data.plan)}</strong>{" "}
                plan will end on the{" "}
                <strong>
                  {format(subscription.data.expiry, "DD MMM YYYY")}
                </strong>
              </>
            ) : (
              <>
                Your payment was successful and your membership has begun. Your{" "}
                <strong>{Case.capital(subscription.data.plan)}</strong>{" "}
                membership will end on the{" "}
                <strong>
                  {format(subscription.data.expiry, "DD MMM YYYY")}
                </strong>
              </>
            )}

            {subscription.data.plan === "student" && (
              <>
                <Spacer height={1} />
                <span>
                  To confirm your student status, please send evidence to{" "}
                  <a href="mailto:ras_membership@soas.ac.uk">
                    ras_membership@soas.ac.uk
                  </a>
                  . This can be in the form of an enrolment certificate by your
                  university or an email from your supervisor, head of
                  department or registry to confirm that you are a student.
                </span>
              </>
            )}
          </div>

          <Spacer height={3} />
          <CenterBox>
            <PanelButton onClick={togglePanel} large id="modal-deactivate">
              Continue <CaretRight />
            </PanelButton>
          </CenterBox>
        </ModalWrapper>
      </Modal>
    </IdleTimer>
  );
};

const DataLoader: React.FC<{
  getSub: Function;
  updateSub: Function;
}> = props => {
  useEffect(() => {
    getSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const subscription = useSelector((state: any) => state.subscription);

  if (!subscription.data) {
    return <React.Fragment />;
  }

  return <Dashboard {...props} />;
};

export default connect(null, { getSub, updateSub })(DataLoader);
