import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GET_GIFTS, GET_GIFT } from "../../../../redux/constants";
import { adminRequest } from "../../../../utils/request";

export const useGetGifts = () => {
  const dispatch = useDispatch();
  const gifts: any = useSelector((state: any) => state.gifts);

  const getGifts = async (page = 1, page_size = 10) => {
    try {
      const { data } = await adminRequest.get(
        `/gifts?page=${page}&page_size=${page_size}`
      );

      dispatch({
        type: GET_GIFTS,
        payload: data.gifts
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [gifts, getGifts] as const;
};

export const useGetGift = () => {
  const dispatch = useDispatch();
  const gift: any = useSelector((state: any) => state.gift);

  const getGift = async (id: number) => {
    try {
      const { data } = await adminRequest.get(`/gifts/${id}`);

      dispatch({
        type: GET_GIFT,
        payload: data.gift
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [gift, getGift] as const;
};
