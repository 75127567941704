import React from "react";

interface ContextProps {
  amount: number;
  source: string;
  donationType: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  postCode: string;
  address: string;
  city: string;
  county: string;
  country: string;
  giftAid: boolean;
  gdpr: boolean;
  setState: (value: any) => void;
}

const Context = React.createContext<ContextProps>({
  amount: 0,
  source: "",
  donationType: "monthly",
  title: "",
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  postCode: "",
  address: "",
  city: "",
  county: "",
  country: "",
  giftAid: false,
  gdpr: false,
  setState: value => {}
});

export default Context;
