import React, { useState, useEffect, useContext } from "react";
import ReactCodeInput from "react-code-input";
import { connect } from "react-redux";
import { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";
import {
  Form,
  H1,
  Separator,
  CustomSteps,
  Notification,
  Title,
  Digits
} from "./styles";
import { Button, Spacer, ScrollToTopOnMount } from "../shared";
import { confirmEmail } from "../../redux/actions/auth";
import { initializeData, resetTimer } from "./regUtils";
import { useRegistrationTimer, useResendEmail } from "./hooks";
import useLoading from "../../hooks/useLoading";

const Step2: React.FC<{
  confirmEmail: Function;
}> = React.memo(({ confirmEmail }) => {
  useRegistrationTimer();
  const [isLoading, load] = useLoading();

  const [regData, setRegData] = useState<{ [key: string]: any } | any>(null);
  const [code, setCode] = useState(0);

  useEffect(() => {
    initializeData().then((regData: any) => {
      setRegData(regData);
    });
  }, []);

  const resendEmail = useResendEmail();

  const theme = useContext(ThemeContext);

  const handleChange = (code: number) => {
    setCode(code);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const confirmationData = {
      activationCode: code,
      email: regData.email
    };

    load(confirmEmail(confirmationData).then(resetTimer));
  };

  const props = {
    inputStyle: {
      fontFamily: "Brown",
      MozAppearance: "textfield",
      width: "92px",
      borderRadius: "22px",
      fontSize: "18px",
      marginBottom: "13px",
      height: "60px",
      paddingLeft: "7px",
      backgroundColor: theme.offWhite2,
      color: theme.black,
      cursor: "pointer",
      border: `1px solid ${theme.gray2}`
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Form onSubmit={handleSubmit}>
        <H1>
          We've sent you <br />a confirmation code.
          <br />
          Please check your email.
        </H1>

        <Separator>
          <hr />
        </Separator>

        <CustomSteps step={2} activateLinks />

        <Notification>
          We’ve sent a six-digit confirmation code to{" "}
          <strong>{regData && regData.email}</strong>. It will expire shortly,
          so enter your code soon
        </Notification>

        <Title>Your confirmation code</Title>

        <Digits>
          <ReactCodeInput
            type="number"
            fields={6}
            {...props}
            onChange={handleChange}
          />
        </Digits>

        <Spacer height={4.6} />

        <Notification className="no-width">
          Keep this window open while checking for your code.
          <br />
          <button
            className="resend-email"
            type="button"
            onClick={() => {
              resendEmail(regData.email);
            }}
          >
            Haven’t received our email yet? Click here to resend.
          </button>
          <br />
          Try your spam folder!
        </Notification>

        <Button type="submit" secondary large disabled={isLoading}>
          {isLoading ? (
            <Loader type="Oval" color={theme.white} height={20} width={20} />
          ) : (
            "Confirm"
          )}
        </Button>
      </Form>
    </>
  );
});

export default connect(null, { confirmEmail })(Step2);
