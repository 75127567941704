import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseURL } from "../../config";
import keysToSnakeCase from "../../utils/keysToSnakeCase";

export const useCreateGiftWithStripe = (callback: Function) => {
  const [loadingState, setLoadingState] = useState(false);

  const createGift = async (payload: any) => {
    setLoadingState(true);

    try {
      await axios.post(`${apiBaseURL}/gifting/card`, keysToSnakeCase(payload));
      callback();
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }

    setLoadingState(false);
  };

  return [loadingState, createGift];
};

export const useCreateGiftWithPaypal = (callback: Function) => {
  const createGift = async (payload: any) => {
    try {
      await axios.post(
        `${apiBaseURL}/gifting/paypal`,
        keysToSnakeCase(payload)
      );

      callback();
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return createGift;
};
