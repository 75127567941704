import React from "react";
import Case from "case";
import { connect } from "react-redux";
import format from "date-fns/format";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PersonalInforamtionContainer } from "./styles";
import Flex from "../shared/Flex";

interface Props extends RouteComponentProps<any> {
  user: {
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    gender_identity: string;
    nationality: string;
    date_of_birth: string;
    post_code: string;
    address: string;
    city: string;
    county: string;
    country: string;
    phone: string;
    created_at: string;
    updated_at: string;
  };
}

const PersonalInforamtion: React.FC<Props> = ({ user }) => {
  return (
    <PersonalInforamtionContainer>
      <Flex>
        <div>
          <p className="title">Fullname:</p>
          <div className="panel">
            {Case.capital(user.title + user.first_name + user.last_name)}{" "}
          </div>

          <p className="title">Email:</p>
          <div className="panel">{user.email} </div>
          <p className="title">Mobile:</p>
          <div className="panel">{user.phone} </div>

          <p className="title">Gender:</p>
          <div className="panel">{user.gender_identity} </div>
          <p className="title">Date of Birth:</p>
          <div className="panel">
            {user.date_of_birth
              ? format(user.date_of_birth, "DD/MM/YYYY")
              : "-"}
          </div>
          <p className="title">Last Updated On:</p>
          <div className="panel"> {format(user.updated_at, "DD/MM/YYYY")} </div>
        </div>
        <div>
          <p className="title">Post Code:</p>
          <div className="panel">{user.post_code} </div>
          <p className="title">Address:</p>
          <div className="panel">{user.address} </div>

          <p className="title">City:</p>
          <div className="panel">{user.city} </div>

          <p className="title">Area:</p>
          <div className="panel">{user.county} </div>
          <p className="title">Country:</p>
          <div className="panel">{user.country} </div>
          <p className="title">Nationality:</p>
          <div className="panel">{user.nationality}</div>
        </div>
      </Flex>
    </PersonalInforamtionContainer>
  );
};

export default withRouter(connect(null, null)(PersonalInforamtion));
