// @ts-nocheck
import React from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";

export const Events = styled.div`
  label {
    margin-right: 1.8rem;
  }
`;

const KeepInTouchBox = styled.div`
  text-align: left;
  width: 100%;
  background: ${({ theme }) => theme.offWhite};
  border-radius: 1rem;
  padding: 4.1rem 3.5rem;
  font-size: 1.4rem;
  border: 1px solid #e0e0e0;
  margin-top: 3.6rem;

  .title {
    margin-left: 0.1rem;
    font-size: 2.4rem;
    line-height: 140.5%;
    letter-spacing: -0.035em;
    margin-bottom: 2.1rem;
  }

  .paragraph {
    line-height: 150%;
    padding-bottom: 2.1rem;
    margin-bottom: 2.5rem;

    &.last {
      margin-top: 3.3rem;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

const KeepInTouch = () => (
  <KeepInTouchBox>
    <p className="title">Keeping In Touch</p>

    <p className="paragraph">
      We would like to keep you updated on our activities, future appeals and
      how your support is making a difference. Please let us know if you would
      like to be contacted and, if so, how you might like to hear from us.
    </p>

    <Checkbox
      checked
      label={
        <strong>
          Yes I am happy for the Royal African Society to email me
        </strong>
      }
    />

    <p className="paragraph last">
      We have a number of newsletters from the different programmes we run,
      which any member of the public can subscribe to. Please select the
      newsletters you would like to receive below.
    </p>

    <Events>
      <Checkbox small checked label={<span>Africa APPG Public Events</span>} />

      <Checkbox small label={<span>Africa Writes</span>} />

      <Checkbox small label={<span>African Arguments</span>} />

      <Checkbox small label={<span>ASAUK News</span>} />
    </Events>
  </KeepInTouchBox>
);

export default KeepInTouch;
