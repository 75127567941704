import axios from "axios";
import { wordPressAPI } from "../../config";

import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_PENDING,
  GET_EVENTS_FAILURE,
  GET_SINGLE_EVENT_SUCCESS,
  GET_SINGLE_EVENT_PENDING,
  GET_SINGLE_EVENT_FAILURE,
  GET_MORE_EVENTS,
} from "../constants";
import { toast } from "react-toastify";

interface Params {
  date: number;
  perPage: number;
  page: number;
  filter: number | string;
}

export const getEvents = (params: Params) => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_EVENTS_PENDING,
    });

    const date = params.date || "";

    const { data } = await axios.get(
      `${wordPressAPI}/events?events_date=${date}&per_page=${
        params.perPage
      }&page=${params.page}&category_id=${params.filter || ""}`
    );

    dispatch({
      type: GET_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_EVENTS_FAILURE,
    });
  }
};

export const getMoreEvents = (params: Params) => async (dispatch: Function) => {
  try {
    const date = params.date || "";

    const { data } = await axios.get(
      `${wordPressAPI}/events?events_date=${date}&per_page=${
        params.perPage
      }&page=${params.page}&category_id=${params.filter || ""}`
    );

    console.log(data);

    dispatch({
      type: GET_MORE_EVENTS,
      payload: data,
    });
  } catch (err) {
    console.log(err);

    toast.error("An error occured");
  }
};

export const getSingleEvent = (slug: string) => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_SINGLE_EVENT_PENDING,
    });

    const { data } = await axios.get(`${wordPressAPI}/event?slug=${slug}`);

    dispatch({
      type: GET_SINGLE_EVENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SINGLE_EVENT_FAILURE,
    });
  }
};
