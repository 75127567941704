// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Modal, Spacer, CenterBox } from "../../../shared";
import CardForm from "./CardForm";
import { stripeKey } from "../../../../config";

const H2 = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 300;
`;

const P = styled.h4`
text-align: center;
`;



const ChangeCard: React.FC<{
  modalActive: boolean;
  toggleModal: () => void;
  setLoaderState?: Function;
  plan: string;
}> = ({ modalActive, toggleModal, plan, setLoaderState }) => {
  return (
    <Modal
      title="Enter Your Card Details"
      modalActive={modalActive}
      toggleModal={toggleModal}
    >
      <Spacer height={3.1} />

      <CenterBox>
        <H2>Card Details</H2>
      </CenterBox>
      <StripeProvider apiKey={stripeKey}>
        <Elements>
          <CardForm toggleModal={toggleModal} plan={plan} />
        </Elements>
      </StripeProvider>
      <br></br>
      <P>Please only click the pay button once and don't go back or refresh the page as you may be charged twice.</P>
    </Modal>
  );
};

export default ChangeCard;
