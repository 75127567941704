import thunk from "redux-thunk";
import storage from "localforage";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

import reducer from "./reducers";

const persistConfig = {
  key: "RAS",
  blacklist: ["subscribers", "subscriber"],
  storage
};

const middleware = applyMiddleware(thunk);
const enhancer = composeWithDevTools(middleware);
const persistedReducer = persistReducer(persistConfig, reducer);

export default () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);

  return { store, persistor };
};
