// @ts-nocheck
import React, { useContext } from "react";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { string, object } from "yup";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";
import { ThemeContext } from "styled-components";
import {
  PrimaryNav,
  SecondaryNav,
  Footer,
  Input,
  InputWrapper as FieldWrapper
} from "../shared";
import { Form, H1, Separator, FormGroup, LoginBtn } from "./styles";
import { login } from "../../redux/actions/auth";
import formHasErrors from "../../utils/formHasErrors";
import { PageTitle } from "../shared/PageTitle";
import useRedirectToDashboard from "../../hooks/useRedirectToDashboard";
import { parseQueryString } from "../../utils/queryString";

interface Props extends RouteComponentProps {
  login: any;
  // history: {
  //   push: Function;
  // };
}

const validationSchema = object().shape({
  email: string()
    .email("Invalid email provided.")
    .required("No email provided."),
  password: string().required("No password provided.")
});

const SignIn: React.FC<Props> = ({ login, history, location }) => {
  useRedirectToDashboard();
  const theme = useContext(ThemeContext);
  const user: any = useSelector(({ user }: any) => user);
  const { origurl } = parseQueryString(location.search);

  const redirectTo = () => {
    const url = new URL(origurl);
    if (url.hostname === "academic.oup.com") {
      window.location.replace(origurl)
    } else {
      history.push("/dashboard");
    }
  }

  return (
    <>
      <PageTitle
        title="Sign in to your Royal African Society account"
        description="Sign In to your Royal African Society account - Royal African Society"
      />
      <SecondaryNav />
      <PrimaryNav />
      <Formik
        onSubmit={credentials => login(credentials, redirectTo)}
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty
        }) => (
          <Form onSubmit={handleSubmit}>
            <H1>Sign in to your Royal African Society account</H1>

            <Separator>
              <hr />
            </Separator>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.email && errors.email)}
                  placeholder="Username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="email"
                  value={values.email}
                />

                <span className="error">{touched.email && errors.email}</span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  placeholder="Password"
                  errored={Boolean(touched.password && errors.password)}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />

                <span className="error">
                  {touched.password && errors.password}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <LoginBtn
                type="submit"
                secondary
                large
                disabled={formHasErrors(errors) || !dirty}
              >
                {user.loading && (
                  <Loader
                    type="Oval"
                    color={theme.white}
                    height={20}
                    width={20}
                  />
                )}
                {!user.loading && "Log In"}
              </LoginBtn>
            </FormGroup>

            <Link to="/reset-password">Forgot your password?</Link>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};

const InputWrapper = styled(FieldWrapper)`
  width: 100%;
`;

export default connect(null, { login })(SignIn);
