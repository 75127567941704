import React from "react";
import { useSelector, connect } from "react-redux";
import { Formik } from "formik";
import {
  FormGroup,
  Input,
  Spacer,
  InputWrapper,
  Select,
  MultiSelect,
} from "../shared";
import { ProfileForm } from "./styles";
import Address from "./Address";
import SaveInfo from "./SaveInfo";
import { updateProfile } from "../../redux/actions/user";
import { professionalInfo } from "./validationSchemas";
import useLoading from "../../hooks/useLoading";

const ProfessionalInformation: React.FC<{
  updateProfile: Function;
}> = React.memo(({ updateProfile }) => {
  const [isLoading, load] = useLoading();
  const user = useSelector(({ user }: { user: any }) => user.data);

  const handleSubmit = (payload: { [key: string]: string }) => {
    load(updateProfile(payload));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          jobTitle: user.job_title,
          secondaryEmail: user.secondary_email,
          organisation: user.organisation,
          industrySector: user.industry_sector,
          workPhone: user.work_phone,
          interests: user.interests,
        }}
        onSubmit={handleSubmit}
        validationSchema={professionalInfo}
      >
        {({
          handleChange,
          handleBlur,
          values,
          dirty,
          setFieldValue,
          handleSubmit,
          touched,
          errors,
          handleReset,
        }) => (
          <ProfileForm onSubmit={handleSubmit}>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.jobTitle && errors.jobTitle)}
                  type="text"
                  name="jobTitle"
                  placeholder="Job Title"
                  value={values.jobTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.jobTitle && errors.jobTitle}
                </span>
              </InputWrapper>

              <InputWrapper>
                <Input
                  errored={Boolean(touched.organisation && errors.organisation)}
                  type="text"
                  placeholder="Company/Organisation"
                  name="organisation"
                  value={values.organisation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.organisation && errors.organisation}
                </span>
              </InputWrapper>
            </FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(
                    touched.secondaryEmail && errors.secondaryEmail
                  )}
                  type="email"
                  name="secondaryEmail"
                  placeholder="Secondary Email"
                  value={values.secondaryEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.secondaryEmail && errors.secondaryEmail}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.workPhone && errors.workPhone)}
                  type="text"
                  name="workPhone"
                  placeholder="Work Telephone"
                  value={values.workPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.workPhone && errors.workPhone}
                </span>
              </InputWrapper>
            </FormGroup>
            <FormGroup>
              <InputWrapper>
                <Select
                  errored={Boolean(
                    touched.industrySector && errors.industrySector
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="industrySector"
                  value={values.industrySector}
                >
                  <option value="">Industry Sector</option>
                  <option value="Academia & Higher Education">
                    Academia & Higher Education
                  </option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Architecture & Design">
                    Architecture & Design
                  </option>
                  <option value="Banking & Financial Services">
                    Banking & Financial Services
                  </option>
                  <option value="Consumer Goods">Consumer Goods</option>
                  <option value="Development">Development</option>
                  <option value="Education">Education</option>
                  <option value="Energy">Energy</option>
                  <option value="Environment">Environment</option>
                  <option value="Fashion & Retail">Fashion & Retail</option>
                  <option value="Film & Television">Film & Television</option>
                  <option value="Government">Government</option>
                  <option value="Healthcare & Public Health">
                    Healthcare & Public Health
                  </option>
                  <option value="Hospitality & Catering">
                    Hospitality & Catering
                  </option>
                  <option value="Human Rights">Human Rights</option>
                  <option value="Infrastructure & Construction">
                    Infrastructure & Construction
                  </option>
                  <option value="Journalism & Media">Journalism & Media</option>
                  <option value="Legal">Legal</option>
                  <option value="Literature & Publishing">
                    Literature & Publishing
                  </option>
                  <option value="Manufacturing & Automotive">
                    Manufacturing & Automotive
                  </option>
                  <option value="Marketing & Communications">
                    Marketing & Communications
                  </option>
                  <option value="Music & Entertainment">
                    Music & Entertainment
                  </option>
                  <option value="NGO & Charity">NGO & Charity</option>
                  <option value="Other">Other</option>
                  <option value="Pharmaceutical">Pharmaceutical</option>
                  <option value="Politics & Policy">Politics & Policy</option>
                  <option value="PR & Lobbying">PR & Lobbying</option>
                  <option value="Private Equity">Private Equity</option>
                  <option value="Professional Services">
                    Professional Services
                  </option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Science & Research">Science & Research</option>
                  <option value="Technology & Innovation">
                    Technology & Innovation
                  </option>
                  <option value="Theatre & Dance">Theatre & Dance</option>
                  <option value="Venture Capital">Venture Capital</option>
                  <option value="Visual Arts">Visual Arts</option>
                </Select>

                <span className="error">
                  {touched.industrySector && errors.industrySector}
                </span>
              </InputWrapper>

              <InputWrapper>
                <MultiSelect
                  initialValue={
                    !values.interests ? undefined : values.interests
                  }
                  onChange={(options: string[]) => {
                    setFieldValue("interests", options.join(", "));
                  }}
                />
              </InputWrapper>
            </FormGroup>

            <Spacer height={15} />

            {dirty && (
              <SaveInfo handleReset={handleReset} isSubmitting={isLoading} />
            )}
          </ProfileForm>
        )}
      </Formik>
      <ProfileForm>
        <Address updateProfile={updateProfile} type="professional" />
      </ProfileForm>
      <Spacer height={6.7} />
    </>
  );
});

export default connect(
  null,
  { updateProfile }
)(ProfessionalInformation);
