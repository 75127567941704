// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const AdminGate: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const admin = useSelector((state: any) => state.admin);

  return (
    <Route
      {...rest}
      render={props =>
        admin.data ? <Component {...props} /> : <Redirect to="/admin" />
      }
    />
  );
};

export default AdminGate;
