import styled, { css } from 'styled-components';

export const CreditCardContainer = styled.div`
  width: 57.6rem;
  height: 6rem;
  border-radius: 4.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;

  ${({ theme }) => css`
    background: ${theme.offWhite2};
    border: 1px solid ${theme.gray2};
  `}

  .StripeElement {
    width: 100%;
    margin: 0 2rem;

    .CardField.CardField--ltr {
      font-size: 20px;
    }
  }
`;
