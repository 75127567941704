import { SET_REG_STEPS } from '../constants';

interface Action {
  type: string,
  payload: any,
}

const initialState = {
  step1Completed: false,
  step2Completed: false,
  step3Completed: false,
  step4Completed: false,
};

const registrationSteps = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_REG_STEPS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default registrationSteps;
