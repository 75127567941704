import axios from "axios";
import { DONATE_SUCCESS, DONATE_FAILURE, DONATE_PENDING } from "../constants";
import { apiBaseURL } from "../../config";
import keysToSnakeCase, { ObjType } from "../../utils/keysToSnakeCase";
import { toast } from "react-toastify";

const donate = (
  { amount, ...donationData }: ObjType,
  redirectTo: Function
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: DONATE_PENDING
    });

    donationData["amount"] = amount;

    const { data } = await axios.post(
      `${apiBaseURL}/donations`,
      keysToSnakeCase(donationData)
    );

    dispatch({
      type: DONATE_SUCCESS,
      payload: data
    });

    redirectTo("/donate/thank-you");
  } catch (err) {
    dispatch({
      type: DONATE_FAILURE
    });
  }
};

export const createPlan = async (amount: number, donationType: string) => {
  try {
    const { data } = await axios.post(`${apiBaseURL}/paypal-plans`, {
      amount,
      donation_type: donationType
    });

    return data;
  } catch (err) {
    toast.error("An error occurred!");
  }
};

export const paypalDonate = (
  donationData: { [key: string]: any },
  redirectTo: Function
) => async (dispatch: Function) => {
  try {
    dispatch({
      type: DONATE_PENDING
    });

    const { data } = await axios.post(
      `${apiBaseURL}/paypal-donations`,
      keysToSnakeCase(donationData)
    );

    dispatch({
      type: DONATE_SUCCESS,
      payload: data
    });

    redirectTo("/donate/thank-you");
  } catch (err) {
    dispatch({
      type: DONATE_FAILURE
    });
  }
};

export default donate;
