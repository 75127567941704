import { toast } from "react-toastify";
import request from "../../utils/request";
import { UPDATE_SUB } from "../constants";

const cancelSub = () => async (dispatch: Function) => {
  try {
    const { data } = await request.put("/user-subscription/update", {
      is_new: false
    });

    dispatch({
      type: UPDATE_SUB,
      payload: data.subscription
    });
  } catch (err) {
    toast.error("An error occurred");
  }
};

export default cancelSub;
