export const plans = [
  "artsAndCulture",
  "student",
  "consultant",
  "individual",
  "friend",
  "lifetime",
  "corporate",
];
export const prices: {
  [key: string]: { desc: string; chargeText?: string; price: number, chargeAmount: number };
} = {
  student: {
    desc:
      "Enjoy our annual membership and accompanying benefits at a reduced student rate. N.B. Applications for student membership will require confirmation of your full-time student status from your university or college.",
    price: 40,
    chargeAmount: 0
  },
  individual: {
    desc:
      "Joint annual membership of the Royal African Society and the African Studies Association of the UK (ASAUK), including free subscription to African Affairs, free or priority access to our events, networking opportunities and much more.",
    price: 85,
    chargeAmount: 0,
  },
  friend: {
    desc:
      "As a friend of the Royal African Society, you will enjoy the full range of membership benefits whilst also supporting our core charitable work. Perfect for those who share our values and vision.",
    price: 120,
    chargeAmount: 0,
  },
  lifetime: {
    desc:
      "For those who are real advocates of the Royal African Society and ASAUK and want to support our charitable work for years to come.",
    price: 1500,
    chargeAmount: 0,
  },
  corporate: {
    desc:
      "Annual membership (including £400 VAT) for companies with a business interest in Africa, offering you valuable insight, high-level networking opportunities, brand visibility and government relations.",
    chargeText:  "+ £70 card processing fee",
    price: 2400,
    chargeAmount: 70,
  },
  artsAndCulture: {
    desc: "Annual membership (including £400 VAT) for companies with a business interest in Africa",
    price: 35,
    chargeAmount: 0,
  },
  consultant: {
    desc: "Annual membership (including £400 VAT) for companies with a business interest in Africa",
    price: 745,
    chargeAmount: 0,
  }
};
