import styled, { css } from "styled-components";

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(34.2rem, 1fr));
  grid-auto-rows: 39.16rem;
  gap: 1rem 1rem;
  grid-template-rows: repeat(auto-fill, 39.16rem);
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23.4rem, 1fr));
  gap: 13.6rem 2.2rem;

  &:last-child {
    grid-column: span 2;
  }

  .newsSection {
    margin-bottom: 4.4rem;
  }
`;

export const NewsContent = styled.div`
  height: 39rem;
  display: flex;
  flex-direction: column;

  figure {
    height: 23.4rem;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      border-radius: 19px;
    }
  }

  .headline {
    margin-top: 2.5rem;
    display: block;
    line-height: 2.2rem;
    padding-bottom: 2.85rem;
    font-size: 1.8rem;
    text-decoration: none;

    ${({ theme }) => css`
      color: ${theme.deepGreen};
    `};
  }
`;

export const Underline = styled.hr`
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.gray3};
    border-top: 1px solid ${theme.gray3};
  `};
`;
