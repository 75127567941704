import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import localforage from "localforage";
import isAfter from "date-fns/is_after";
import { apiBaseURL } from "../../config";
import {
  SET_REG_STEPS,
  SUBSCRIBE_PENDING,
  SUBSCRIBE_SUCCESS,
  GET_SUB_SUCCESS,
  SUBSCRIBE_FAILURE
} from "../../redux/constants";
import { toast } from "react-toastify";
import keysToSnakeCase from "../../utils/keysToSnakeCase";
import request from "../../utils/request";

const payload = {
  step1Completed: false,
  step2Completed: false,
  step3Completed: false,
  step4Completed: false
};

export const useRegistrationTimer = () => {
  const [expiry, setExpiry] = useState<Date | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    localforage
      .getItem<Promise<Date> | Promise<undefined>>("reg-expiry")
      .then((expiry: any) => {
        if (expiry) {
          setExpiry(expiry);
        }
      });
  }, []);

  useInterval(() => {
    if (expiry) {
      if (isAfter(new Date(), expiry)) {
        localforage.removeItem("reg-expiry");

        dispatch({
          type: SET_REG_STEPS,
          payload
        });
      }
    }
  });
};

const useInterval = (callback: Function, delay = 500) => {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
};

export const useResendEmail = () => {
  const resendEmail = async (email: string) => {
    axios.post(`${apiBaseURL}/register/resend-email`, { email });

    toast.success("Email sent!");
  };

  return resendEmail;
};

export function usePaypalSubscribe() {
  const dispatch = useDispatch();

  async function paypalSubscribe(
    payload: { [key: string]: string },
    callback: Function
  ) {
    try {
      dispatch({
        type: SUBSCRIBE_PENDING
      });

      const { data } = await request.post(
        "/paypal-subscriptions",
        keysToSnakeCase(payload)
      );

      await localforage.setItem("token", data.access_token);

      dispatch({
        type: SUBSCRIBE_SUCCESS,
        payload: data.user
      });

      dispatch({
        type: GET_SUB_SUCCESS,
        payload: data.subscription
      });

      dispatch({
        type: SET_REG_STEPS,
        payload: {
          step4Completed: true
        }
      });

      callback("/dashboard");
    } catch (err) {
      let err_msg = err.response.data.error || 'An error occured';
      toast.error(err_msg);

      dispatch({
        type: SUBSCRIBE_FAILURE
      });
    }
  }

  return paypalSubscribe;
}
