import { GET_FAQ_SUCCESS, GET_FAQ_PENDING, GET_FAQ_FAILURE,
} from '../constants';

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null,
};

interface IState {
  loading: boolean,
  success: boolean,
  failed: boolean,
  data: null | object,
}

interface IAction {
  type: string,
  payload: any,
}

export const faqs = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case GET_FAQ_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      }

    case GET_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false,
      }

    case GET_FAQ_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false,
      }

    default:
      return state;
  }
}