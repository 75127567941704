// @ts-nocheck
import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import Case from "case";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Loader from "react-loader-spinner";
import "react-table/react-table.css";
import { Formik } from "formik";
import { string, object, date } from "yup";
import styled, { ThemeContext } from "styled-components";
import {
  PageContainer,
  Spacer,
  PageLoader,
  Modal,
  FormGroup,
  InputWrapper as InputWrap,
  Input,
  Select,
  Button,
} from "../../../shared";
import { H1, HR, GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { PageTitle } from "../../../shared/PageTitle";
import { useCreateSubscriber } from "./hooks";
import useLoading from "../../../../hooks/useLoading";
import { Header, Column, Form } from "../style";
import { format } from "date-fns";
import formHasErrors from "../../../../utils/formHasErrors";
import Search from "./Search";

const validationSchema = object().shape({
  firstName: string().required("First name not provided."),
  title: string().notOneOf(["Title *"], "Title is required."),
  lastName: string().required("Last name not provided."),
  email: string()
    .email("Invalid email provided.")
    .required("Email not provided."),
  plan: string()
    .oneOf(
      ["student", "individual", "friend", "lifetime", "corporate", "artsAndCulture", "consultant"],
      "Plan is invalid."
    )
    .required(),
  orgainisation: string().nullable(),
  genderIdentity: string().oneOf(
    ["Male", "Female", "Prefer not to say", "Non-Binary"],
    "Gender is required."
  ),
  created_at: date()
  .min(new Date(), "You cannot provide past date for creation")
  .required(),
  expiry: date()
    .min(addDays(new Date(), 1), "You must provide a future date")
    .required(),
});

interface Props {
  subscribers: { data: any[]; last_page: number };
  setFilter: (filter: {
    searchTerm?: string;
    filter?: string;
    page?: number;
    page_size?: number;
  }) => void;
  controls: {
    searchTerm: string;
    filter: string;
    page: number;
    page_size: number;
  };
  loading: boolean;
}

const Subscribers: React.FC<Props> = ({
  setFilter,
  loading,
  subscribers,
  controls,
}) => {
  const [isCreateLoading, loadCreate] = useLoading();
  const [modalState, setModalState] = useState(false);
  const createSubscriber = useCreateSubscriber();
  const theme = useContext(ThemeContext);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const columns = [
    {
      Header: () => (
        <Header>
          <h3>Created on</h3>
        </Header>
      ),
      id: "createdOn",
      accessor: (d: { d: { [key: string]: string }[] }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {format(props.value.created_at, "DD/MM/YYYY")}
          </Link>
        </Column>
      ),
    },
    {
      Header: () => (
        <Header>
          <h3>Full name</h3>
        </Header>
      ),
      id: "fullName",
      accessor: (d: { d: { [key: string]: string }[] }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {props.value.title +
              " " +
              props.value.first_name +
              " " +
              props.value.last_name}
          </Link>
        </Column>
      ),
    },
    {
      Header: () => (
        <Header>
          <h3>Email</h3>
        </Header>
      ),
      id: "email",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {props.value.email}
          </Link>
        </Column>
      ),
    },
    {
      Header: () => (
        <Header>
          <h3>Plan</h3>
        </Header>
      ),
      id: "plan",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {Case.capital(props.value.plan)}
          </Link>
        </Column>
      ),
    },
    {
      Header: (props: any) => (
        <Header>
          <h3>Status</h3>
        </Header>
      ),
      id: "status",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {Case.capital(props.value.status)}
          </Link>
        </Column>
      ),
    },
    {
      Header: (props: any) => (
        <Header>
          <h3>Expiry Date</h3>
        </Header>
      ),
      id: "expiry",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/subscribers/${props.value.id}`}
            className="number"
          >
            {format(props.value.expiry, "DD/MM/YYYY")}
          </Link>
        </Column>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="All Members" description="Manage all ASA UK Members" />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>
          All
          <br />
          Members
        </H1>

        <HR />

        <GoBack>
          <Link to="/admin/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={7.5} />

        {loading && <PageLoader />}

        {!loading && subscribers.data && (
          <>
            <div>
              <CreateUserBtn onClick={toggleModal}>
                &#43; Create Member
              </CreateUserBtn>
            </div>

            <Spacer height={2} />

            <Search setFilter={setFilter} loading={loading} />

            <Spacer height={2} />

            <ReactTable
              data={subscribers.data}
              columns={columns}
              defaultPageSize={10}
              minRows={1}
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              manual
              pages={subscribers.last_page}
              previousText="Previous Page"
              nextText="Next Page"
              // loadingText="Loading Subscribers.."
              noDataText="No members found"
              onFetchData={(state) => {
                setFilter({
                  page: state.page + 1,
                  page_size: state.pageSize,
                });
              }}
            />
          </>
        )}

        <Modal
          title="Create Member"
          modalActive={modalState}
          toggleModal={toggleModal}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: "",
              firstName: "",
              lastName: "",
              email: "",
              workEmail: "",
              plan: "",
              genderIdentity: "",
              organisation: "",
              created_at: addDays(new Date(), 1),
              expiry: addDays(new Date(), 1),
              directDebit: "",
            }}
            validationSchema={validationSchema}
            onSubmit={({ created_at, expiry, ...formData }) => {
              const payload = {
                ...formData,
                expiry: `${expiry.getFullYear()}-${expiry.getMonth() +
                  1}-${expiry.getDate()}`,
                created_at: `${created_at.getFullYear()}-${created_at.getMonth() +
                  1}-${created_at.getDate()}`,
              };
              loadCreate(createSubscriber(payload, toggleModal));
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              values,
              handleChange,
              dirty,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <InputWrapper>
                    <Select
                      errored={Boolean(touched.title && errors.title)}
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Title</option>
                      <option value="Ms">Ms</option>
                      <option value="Miss">Miss</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Sir">Sir</option>
                      <option value="Revd">Revd</option>
                      <option value="Dame">Dame</option>
                      <option value="Baroness">Baroness</option>
                      <option value="Lord">Lord</option>
                      <option value="Earl">Earl</option>
                      <option value="Rt Hon">Rt Hon</option>
                      <option value="Rt Hon Sir">Rt Hon Sir</option>
                      <option value="Mx">Mx</option>
                    </Select>

                    <span className="error">
                      {touched.title && errors.title}
                    </span>
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="firstName"
                      errored={Boolean(touched.firstName && errors.firstName)}
                      value={values.firstName}
                      type="text"
                      placeholder="First name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <span className="error">
                      {touched.firstName && errors.firstName}
                    </span>
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="lastName"
                      value={values.lastName}
                      type="text"
                      placeholder="Last name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="email"
                      errored={Boolean(touched.email && errors.email)}
                      value={values.email}
                      type="email"
                      placeholder="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <span className="error">
                      {touched.email && errors.email}
                    </span>
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="organisation"
                      errored={Boolean(
                        touched.organisation && errors.organisation
                      )}
                      value={values.organisation}
                      type="text"
                      placeholder="Organisation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <span className="error">
                      {touched.organisation && errors.organisation}
                    </span>
                  </InputWrapper>
                </FormGroup>
                <FormGroup>
                  <InputWrapper>
                    <Select
                      value={values.genderIdentity}
                      errored={Boolean(
                        touched.genderIdentity && errors.genderIdentity
                      )}
                      name="genderIdentity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Gender *</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Prefer not to say">
                        Prefer not to say
                      </option>
                      <option value="Non-Binary">Non-Binary</option>
                    </Select>

                    <span className="error">
                      {touched.genderIdentity && errors.genderIdentity}
                    </span>
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Select
                      errored={Boolean(values.plan && errors.plan)}
                      value={values.plan}
                      name="plan"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Plan *</option>
                      <option value="student">Student</option>
                      <option value="individual">Individual</option>
                      <option value="friend">Friend</option>
                      <option value="lifetime">Lifetime</option>
                      <option value="corporate">Corporate</option>
                      <option value="artsAndCulture">Arts and Culture</option>
                      <option value="consultant">Consultant</option>
                    </Select>

                    <span className="error">{touched.plan && errors.plan}</span>
                  </InputWrapper>
                </FormGroup>
                <br />
                <br />
                <FormGroup>
                  <InputWrapper>
                    <label>Subscription start date:</label>

                    <DatePicker
                      selected={values.created_at}
                      onChange={(date) => {
                        setFieldValue("created_at", date);
                      }}
                    />

                    <span className="error">{errors.created_at}</span>
                  </InputWrapper>
                </FormGroup>
                <br />
                <br />
                <FormGroup>
                  <InputWrapper>
                    <label>Subscription expiry:</label>

                    <DatePicker
                      selected={values.expiry}
                      onChange={(date) => {
                        setFieldValue("expiry", date);
                      }}
                    />

                    <span className="error">{errors.expiry}</span>
                  </InputWrapper>
                </FormGroup>
                <FormGroup>
                  <InputWrapper>
                    <Select
                      value={values.directDebit}
                      name="directDebit"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Direct Debit</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Select>
                  </InputWrapper>
                </FormGroup>

                <Button
                  type="submit"
                  large
                  disabled={formHasErrors(errors) || isCreateLoading || !dirty}
                >
                  {isCreateLoading ? (
                    <Loader
                      type="Oval"
                      color={theme.white}
                      height={20}
                      width={20}
                    />
                  ) : (
                    "Create"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>

        <Spacer height={10.5} />
      </PageContainer>
    </>
  );
};

const CreateUserBtn = styled.button`
  color: ${({ theme }) => theme.orange};
  font-size: 1.8rem;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const InputWrapper = styled(InputWrap)`
  width: 100%;

  label {
    text-align: left;
    display: inline-block;
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    color: #828282;
    left: 3.9rem;
    top: -2.7rem;
    position: absolute;
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      width: 100%;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 4.8rem;
      padding: 2.2rem 3.7rem;
      font-size: 2rem;
      outline: transparent;
      line-height: 21px;
      font-weight: 300;
      color: ${({ theme }) => theme.gray};
    }
  }
`;

export default Subscribers;
