import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { FormGroup, Input, Button, Spacer } from "../shared";
import { changePassword } from "../../redux/actions/user";
import { Form } from "./styles";

const ChangePassword: React.FC<{ changePassword: Function }> = React.memo(
  ({ changePassword }) => (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }}
      onSubmit={(values, { resetForm }) => {
        const { confirmPassword, ...payload } = values;

        changePassword(payload, resetForm);
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, errors }) => (
        <Form onSubmit={handleSubmit}>
          <h2>Change password</h2>
          <Spacer height={5.5} />
          <FormGroup>
            <Input
              onBlur={handleBlur}
              type="password"
              placeholder="Old Password"
              value={values.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              onBlur={handleBlur}
              value={values.newPassword}
              onChange={handleChange}
              type="password"
              name="newPassword"
              placeholder="New Password"
            />
          </FormGroup>
          <FormGroup>
            <Input
              onBlur={handleBlur}
              value={values.confirmPassword}
              onChange={handleChange}
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
            />
          </FormGroup>
          <Spacer height={1.5} />
          <Button secondary large type="submit">
            Change
          </Button>
          <Spacer height={3.5} />
        </Form>
      )}
    </Formik>
  )
);

export default connect(null, { changePassword })(ChangePassword);
