// @ts-nocheck
import React, { useState } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { connect } from "react-redux";
import { string, object, ref } from "yup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  PrimaryNav,
  SecondaryNav,
  Footer,
  Input,
  InputWrapper as FieldWrapper
} from "../shared";
import formHasErrors from "../../utils/formHasErrors";
import { Form, H1, Separator, FormGroup, LoginBtn } from "./styles";
import { parseQueryString } from "../../utils/queryString";
import {
  initiatePasswordReset,
  resetPassword
} from "../../redux/actions/resetPassword";
import { PageTitle } from "../shared/PageTitle";

const InputWrapper = styled(FieldWrapper)`
  width: 100%;
`;

const validationSchema = object().shape({
  email: string()
    .email("Invalid email provided.")
    .required("No email provided.")
});

const resetValidationSchema = object().shape({
  password: string()
    .min(8, "Password have at least 8 characters.")
    .required("Password not provided."),
  confirmPassword: string()
    .oneOf([ref("password"), ""], "Passwords must match.")
    .required("Please confirm password.")
});

interface Props extends RouteComponentProps {
  initiatePasswordReset: Function;
  resetPassword: Function;
}

const ResetPassword: React.FC<Props> = ({
  location,
  resetPassword,
  initiatePasswordReset,
  history
}) => {
  const { token } = parseQueryString(location.search);

  return (
    <>
      <PageTitle
        title="Set a new password"
        description="Set a new account password on the Royal African Society"
      />
      <SecondaryNav />
      <PrimaryNav />

      {token ? (
        <ResetPasswordForm
          action={resetPassword}
          token={token}
          redirect={history.push}
        />
      ) : (
        <SendPasswordReset action={initiatePasswordReset} />
      )}
      <Footer />
    </>
  );
};

const ResetPasswordForm: React.FC<{
  action: Function;
  token: string;
  redirect: Function;
}> = ({ action, token, redirect }) => (
  <Formik
    onSubmit={payload => {
      action({
        token,
        password: payload.password
      }).then(() => {
        redirect("/login");
      });
    }}
    initialValues={{ password: "", confirmPassword: "" }}
    validationSchema={resetValidationSchema}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit,
      dirty
    }) => (
      <Form onSubmit={handleSubmit}>
        <H1>Set a new password</H1>

        <Separator>
          <hr />
        </Separator>

        <FormGroup>
          <InputWrapper>
            <Input
              errored={Boolean(touched.password && errors.password)}
              placeholder="Password *"
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              name="password"
              value={values.password}
            />

            <span className="error">{touched.password && errors.password}</span>
          </InputWrapper>
        </FormGroup>

        <FormGroup>
          <InputWrapper>
            <Input
              errored={Boolean(
                touched.confirmPassword && errors.confirmPassword
              )}
              placeholder="Confirm Password *"
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
            />

            <span className="error">
              {touched.confirmPassword && errors.confirmPassword}
            </span>
          </InputWrapper>
        </FormGroup>

        <FormGroup>
          <LoginBtn
            type="submit"
            secondary
            large
            disabled={formHasErrors(errors) || isSubmitting || !dirty}
          >
            Save Password
          </LoginBtn>
        </FormGroup>
      </Form>
    )}
  </Formik>
);

const SendPasswordReset: React.FC<{ action: Function }> = ({ action }) => {
  const [loadingState, setLoadingState] = useState(false);

  return (
    <Formik
      onSubmit={({ email }) => {
        setLoadingState(true);
        action({ email }).then(() => setLoadingState(false));
      }}
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        dirty
      }) => (
        <Form onSubmit={handleSubmit}>
          <H1>Reset Password</H1>

          <Separator>
            <hr />
          </Separator>

          <FormGroup>
            <InputWrapper>
              <Input
                errored={Boolean(touched.email && errors.email)}
                placeholder="Email address *"
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                name="email"
                value={values.email}
              />

              <span className="error">{touched.email && errors.email}</span>
            </InputWrapper>
          </FormGroup>

          <FormGroup>
            <LoginBtn
              type="submit"
              secondary
              large
              disabled={formHasErrors(errors) || loadingState || !dirty}
            >
              Submit
            </LoginBtn>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default connect(null, { initiatePasswordReset, resetPassword })(
  withRouter(ResetPassword)
);
