import React, { useEffect } from "react";
import format from "date-fns/format";
import { connect, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import { getEvents } from "../../../redux/actions/event";
import { Calendar } from "./styles";
import useLoading from "../../../hooks/useLoading";
import { PageLoader } from "../../shared";
import { numberCalendarItems, initialPage } from "../../../config";

function toISO(date: string): string {
  return date
    .split("/")
    .reverse()
    .join("-");
}

const formatEvents = (events: any, path: string) =>
  events.map((event: any) => ({
    title: event.post_title,
    date: format(toISO(event.events_date), "YYYY-MM-DD"),
    url: `${path}/${event.slug}`
  }));

const CalendarView: React.FC<{
  getEvents: Function;
  controls: any;
}> = React.memo(({ getEvents, controls }) => {
  const events = useSelector((state: any) => state.events.data);
  const [isLoading, load] = useLoading();
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const calendarControls = {
      ...controls,
      perPage: numberCalendarItems,
      page: initialPage
    };

    load(getEvents(calendarControls));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls]);

  const formattedEvents = formatEvents(events.data, path);

  return isLoading ? (
    <PageLoader />
  ) : (
    <Calendar>
      {events && (
        <FullCalendar
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin]}
          eventClick={(info: any) => {
            info.jsEvent.preventDefault();
            history.push(info.event.url);
          }}
          editable={true}
          weekends
          events={formattedEvents}
        />
      )}
    </Calendar>
  );
});

export default connect(null, { getEvents })(CalendarView);
