import { useEffect, useState } from "react";
import { adminRequest } from "../../../utils/request";

export function useCountDonations() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const countDonations = async () => {
      const { data } = await adminRequest.get("/donations/count");

      setCount(data.count);
    };

    countDonations();
  }, []);

  return count;
}

export function useCountGifts() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const countDonations = async () => {
      const { data } = await adminRequest.get("/gifts/count");

      setCount(data.count);
    };

    countDonations();
  }, []);

  return count;
}

export function useCountSubscriber() {
  const [count, setCount] = useState({
    total: 0,
    active: 0
  });

  useEffect(() => {
    const countDonations = async () => {
      const { data } = await adminRequest.get("/subscribers/count");

      setCount(data.count);
    };

    countDonations();
  }, []);

  return count;
}
