// @ts-nocheck
import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Case from "case";
import { Spacer, Steps, Button, CenterBox, FullPageLoader } from "../../shared";
import { parseQueryString } from "../../../utils/queryString";
import {
  Container,
  H1,
  P,
  StepsWrapper,
  Billing,
  PayPalButton,
  Warning,
  PaymentDets
} from "./styles";
import { prices } from "./metaData";
import { PageTitle } from "../../shared/PageTitle";
import { usePay } from "./hooks";
import usePaypal from "../../../hooks/usePaypal";
import { useAddCard } from "./../hooks";
import ChangeCard from "../ChangeCard";

const paypalPlans: { [key: string]: any } = {
  student: process.env.REACT_APP_STUDENT,
  individual: process.env.REACT_APP_INDIVIDUAL,
  friend: process.env.REACT_APP_FRIEND,
  lifetime: process.env.REACT_APP_LIFETIME,
  corporate: process.env.REACT_APP_CORPORATE
};

const CardCharge: React.FC<RouteComponentProps> = ({ history, match }) => {
  const payWithPaypal = usePay(() => history.push("/dashboard/account"));
  const location = useLocation();

  const { plan } = parseQueryString(location.search);

  const isLoading = usePaypal({
    containerId: "#paypal-button-holder",
    action: payWithPaypal,
    planId: paypalPlans[plan],
    planName: plan,
    buttonType: "pay"
  });

  const isRenew = match.path.includes("renew-plan");

  const {
    modalState: addCardModalState,
    toggleModal: toggleCardModal
  } = useAddCard();

  return (
    <>
      <PageTitle
        title={`Lets finish this up! Upgrade to ${plan.charAt(0).toUpperCase() +
          plan.slice(1)}'s Plan`}
        description={`Lets finish this up! Upgrade to ${plan
          .charAt(0)
          .toUpperCase() + plan.slice(1)} Plan`}
      />
      <Container>
        <Spacer height={11} />

        <H1>Lets finish this up!</H1>

        <Spacer height={1.3} />

        <P>{Case.capital(plan)} Plan</P>

        <Spacer height={5.8} />

        <StepsWrapper>
          <Steps maxSteps={2} step={2} />
        </StepsWrapper>

        <Billing>
          <p className="title">Bill</p>

          <Spacer height={2} />

          <p className="price">
            <sup>£</sup>
            {prices[plan].price + prices[plan].chargeAmount}
            <sub>.00</sub>
          </p>

          <PaymentDets>
            <CenterBox>
              <p className="title">
                {isRenew ? "Renew" : "Upgrade"} with PayPal:
              </p>
            </CenterBox>

            {!isRenew && (
              <CenterBox>
                <Warning>
                  <strong>Warning</strong>: To upgrade your account with PayPal,
                  your ongoing subscription will be cancelled and a new
                  subscription will be created.
                  <Spacer height={2} />
                </Warning>
              </CenterBox>
            )}

            <CenterBox>
              <PayPalButton id="paypal-button-holder" hide={false} />
            </CenterBox>

            <Spacer height={5} />

            <CenterBox>
              <p className="title">Or Change Payment Method:</p>
            </CenterBox>

            <CenterBox>
              <Button secondary large onClick={toggleCardModal}>
                Add Credit/Debit Card
              </Button>
            </CenterBox>
          </PaymentDets>

          <Spacer height={22.3} />
        </Billing>

        <ChangeCard
          toggleModal={toggleCardModal}
          modalActive={addCardModalState}
        />
      </Container>

      {isLoading && <FullPageLoader />}
    </>
  );
};

export default CardCharge;
