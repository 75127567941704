import React, { useState, useContext } from "react";
import Case from "case";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import format from "date-fns/format";
import { connect } from "react-redux";
import { ThemeContext } from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BillingContainer, ModalText } from "./styles";
import { Button, Spacer, Modal, CenterBox } from "../shared";
import cancelSub from "../../redux/actions/cancelSub";
import ChangeCard from "./ChangeCard";

interface Props extends RouteComponentProps<any> {
  user: { id: number; first_name: string; last_name: string; card: string };
  subscription: any;
  cancelSub: Function;
}

const Billing: React.FC<Props> = ({
  user,
  subscription,
  history,
  cancelSub,
}) => {
  const [modalState, setModalState] = useState(false);
  const [confirmationState, setConfirmationState] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const toggleConfirmation = () => {
    setConfirmationState(!confirmationState);
  };

  const handleUpgrade = () => {
    if (subscription.plan === "corporate") {
      toast.success("You are already subscribed to the maximum plan.");
    } else {
      history.push("/dashboard/account/upgrade-plan");
    }
  };

  const handleCancel = async () => {
    if (subscription.plan === "student") {
      toast.success("This is a one-off subscription plan!");
    } else {
      setLoading(true);

      await cancelSub();

      toggleConfirmation();
    }
  };

  return (
    <BillingContainer>
      <p className="title">Your Plan:</p>
      <div className="panel">{Case.capital(subscription.plan)} Account</div>

      <p className="expires-on">
        Membership ID:{" "}
        {user &&
          Case.upper(
            `RAS${user.first_name[0]}${user.last_name[0]}${subscription.id}${user.id}`
          )}
      </p>

      <p className="expires-on">
        Expires on {format(subscription.expiry, "DD/MM/YYYY")}
      </p>

      <Spacer height={1.6} />

      {Boolean(subscription.active) && (
        <Button
          type="button"
          onClick={handleUpgrade}
          large
          disabled={!subscription.active}
        >
          Upgrade Plan
        </Button>
      )}

      {!Boolean(subscription.active) && (
        <Button
          type="button"
          onClick={() => {
            history.push("/dashboard/account/renew-plan");
          }}
          large
          secondary
        >
          Renew Plan
        </Button>
      )}

      <Spacer height={2.6} />
      {
        subscription.subscription_provider !== 'gift' && (
          <button
            className="cancel-plan"
            onClick={toggleConfirmation}
            disabled={!subscription.active}
          >
            Cancel Plan
          </button>
        )
      }

      <Spacer height={10.3} />

      {subscription && subscription.subscription_provider === "stripe" && (
        <>
          <p className="title">Payment Method:</p>

          <p className="name">
            {user && user.first_name} {user && user.last_name}
          </p>

          <p className="card-details">**** **** **** {user && user.card}</p>

          <Button secondary large onClick={toggleModal}>
            Change Card
          </Button>
        </>
      )}

      {subscription && subscription.subscription_provider === "paypal" && (
        <>
          <p className="title">Change Payment Method:</p>

          <Button secondary large onClick={toggleModal}>
            Add Credit/Debit Card
          </Button>
        </>
      )}

      <Spacer height={23.7} />

      <ChangeCard modalActive={modalState} toggleModal={toggleModal} />

      <Modal
        title="Cancel Plan"
        modalActive={confirmationState}
        toggleModal={toggleConfirmation}
      >
        <ModalText>
          You will have full access to your account through the end of your next
          billing cycle on{" "}
          <strong>{format(subscription.expiry, "DD/MM/YYYY")}</strong>, after
          which you will have to renew your subscription to continue using the
          website.
        </ModalText>
        <br />
        <br />

        <CenterBox>
          <Button
            onClick={handleCancel}
            large
            secondary
            disabled={!subscription.active || loading}
          >
            {loading ? (
              <Loader type="Oval" color={theme.white} height={20} width={20} />
            ) : (
              "Continue"
            )}
          </Button>
        </CenterBox>
      </Modal>
    </BillingContainer>
  );
};

export default withRouter(
  connect(
    null,
    { cancelSub }
  )(Billing)
);
