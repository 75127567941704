import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { ProfileForm } from "./styles";
import { FormGroup, Spacer, Checkbox, PageLoader } from "../shared";
import SaveInfo from "./SaveInfo";
import useLoading from "../../hooks/useLoading";
import { useNewsletter } from "./hooks";

const NewsletterInfo: React.FC = () => {
  const [isLoading, load] = useLoading();
  const {
    newsletterLoading,
    newsletters,
    subscribed,
    subscribe
  } = useNewsletter();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...subscribed }}
        onSubmit={(values, { resetForm }) => {
          load(subscribe(values, resetForm));
        }}
      >
        {({
          handleBlur,
          handleSubmit,
          values,
          handleChange,
          dirty,
          handleReset
        }) => (
          <ProfileForm
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(event);
            }}
          >
            <Spacer height={2} />

            {newsletterLoading && <PageLoader />}

            <NewslettesrWrapper>
              {newsletters &&
                subscribed &&
                newsletters.map((nl: { [key: string]: string }) => (
                  <FormGroup key={nl.id}>
                    <Checkbox
                      label={nl.name}
                      // @ts-ignore
                      checked={values[nl.id]}
                      name={nl.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                ))}
            </NewslettesrWrapper>

            <Spacer height={4} />

            {!newsletterLoading && (
              <NewslettesrWrapper>
                <FormGroup>
                  <a
                    href="https://africanarguments.org/newsletter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Subscribe to the premium African Arguments Insiders
                    Newsletter here.
                  </a>
                </FormGroup>
              </NewslettesrWrapper>
            )}

            {dirty && (
              <SaveInfo handleReset={handleReset} isSubmitting={isLoading} />
            )}
          </ProfileForm>
        )}
      </Formik>
      <Spacer height={15} />
    </>
  );
};

const NewslettesrWrapper = styled.div`
  font-size: 1.8rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.orange};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default NewsletterInfo;
