import axios from "axios";
import {
  GET_NEWSLETTERS_FAILURE,
  GET_NEWSLETTERS_PENDING,
  GET_NEWSLETTERS_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAILURE,
  SUBSCRIBE_NEWSLETTER_PENDING,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
} from "../constants";
import { apiBaseURL } from "../../config";

export const getNewsletters = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_NEWSLETTERS_PENDING,
    });

    const { data } = await axios.get(`${apiBaseURL}/mailing-lists`);

    dispatch({
      type: GET_NEWSLETTERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_NEWSLETTERS_FAILURE,
    });
  }
};

export const subscribeToNewsletters = (payload: {
  interests: string[];
  email: string;
  first_name: string;
  last_name: string;
}) => async (dispatch: Function) => {
  try {
    dispatch({
      type: SUBSCRIBE_NEWSLETTER_PENDING,
    });

    await axios.post(`${apiBaseURL}/mailing-lists`, payload);

    dispatch({
      type: SUBSCRIBE_NEWSLETTER_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: SUBSCRIBE_NEWSLETTER_FAILURE,
    });
  }
};
