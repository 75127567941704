import localForage from 'localforage';

const tempClearCache = async () => {
  const cleared = localStorage.getItem("tempCleared");

  if (cleared !== "yes") {
    await localForage.clear();

    localStorage.setItem("tempCleared", "yes")
  }
};

export default tempClearCache
