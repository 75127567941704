import { toast } from "react-toastify";
import localforage from "localforage";
import request from "../../utils/request";
import {
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_PENDING,
  SET_REG_STEPS,
  GET_SUB_SUCCESS
} from "../constants";
import keysToSnakeCase, { ObjType } from "../../utils/keysToSnakeCase";

const subscribe = (subscriptionData: ObjType, redirectTo: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: SUBSCRIBE_PENDING
    });

    const snakeCasedData = keysToSnakeCase(subscriptionData);

    const { data } = await request.post("/subscriptions", snakeCasedData);

    dispatch({
      type: SUBSCRIBE_SUCCESS,
      payload: data.user
    });

    dispatch({
      type: GET_SUB_SUCCESS,
      payload: data.subscription
    });

    dispatch({
      type: SET_REG_STEPS,
      payload: {
        step4Completed: true
      }
    });

    await localforage.setItem("token", data.access_token);

    redirectTo("/dashboard");
  } catch (err) {
    let err_msg = err.response.data.error || 'An error occured';
    toast.error(err_msg);
    dispatch({
      type: SUBSCRIBE_FAILURE
    });
  }
};

export default subscribe;
