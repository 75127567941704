import axios from "axios";
import localforage from "localforage";
import { apiBaseURL } from "../config";
import hardRedirectTo from "./hardRedirectTo";

const instance = axios.create({
  baseURL: apiBaseURL
});

instance.interceptors.request.use(async config => {
  const accessToken = await localforage.getItem("token");

  if (accessToken) {
    return {
      ...config,
      headers: {
        Authorization: accessToken
      }
    };
  }

  return config;
});

instance.interceptors.response.use(
  response => response,

  error => {
    if (
      error.response &&
      error.response.data.error === "Provided token is expired."
    ) {
      localforage.removeItem("token");
      localforage.removeItem("persist:RAS");

      hardRedirectTo("/login");
    } else {
      throw error;
    }
  }
);

const adminInstance = axios.create({
  baseURL: `${apiBaseURL}/admin`
});

adminInstance.interceptors.request.use(async config => {
  const accessToken = await localforage.getItem("admin_token");

  if (accessToken) {
    return {
      ...config,
      headers: {
        Authorization: accessToken
      }
    };
  }

  return config;
});

adminInstance.interceptors.response.use(
  response => response,
  async error => {
    if (
      error.response &&
      error.response.data.error === "Provided token is expired."
    ) {
      await localforage.removeItem("admin_token");
      await localforage.removeItem("persist:RAS");
      hardRedirectTo("/admin");
    } else {
      throw error;
    }
  }
);

export const adminRequest = adminInstance;

export default instance;
