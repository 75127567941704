import { toast } from "react-toastify";
import { adminRequest } from "../../../../utils/request";
import keysToSnakeCase from "../../../../utils/keysToSnakeCase";

export const useChangePassword = () => {
  const changePassword = async (payload: any, callback: Function) => {
    try {
      await adminRequest.post("/change-password", keysToSnakeCase(payload));

      callback();

      toast.success("Password changed successfully");
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  return changePassword;
};
