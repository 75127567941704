import React, { useContext } from "react";
import Loader from "react-loader-spinner";
import { ThemeContext } from "styled-components";
import { Formik } from "formik";
import { FormGroup, Input, Button, Spacer } from "../../../shared";
import { Form } from "./styles";
import useLoading from "../../../../hooks/useLoading";
import { useChangePassword } from "./hooks";

const ChangePassword: React.FC = React.memo(() => {
  const [isLoading, load] = useLoading();
  const changePassword = useChangePassword();
  const theme = useContext(ThemeContext);

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }}
      onSubmit={(values, { resetForm }) => {
        const { confirmPassword, ...payload } = values;

        load(changePassword(payload, resetForm));
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, errors }) => (
        <Form onSubmit={handleSubmit}>
          <h2>Change password</h2>
          <Spacer height={5.5} />
          <FormGroup>
            <Input
              onBlur={handleBlur}
              type="password"
              placeholder="Old Password"
              value={values.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              onBlur={handleBlur}
              value={values.newPassword}
              onChange={handleChange}
              type="password"
              name="newPassword"
              placeholder="New Password"
            />
          </FormGroup>
          <FormGroup>
            <Input
              onBlur={handleBlur}
              value={values.confirmPassword}
              onChange={handleChange}
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
            />
          </FormGroup>
          <Spacer height={1.5} />
          <Button secondary large type="submit" disabled={isLoading}>
            {isLoading ? (
              <Loader type="Oval" color={theme.white} height={20} width={20} />
            ) : (
              "Change"
            )}
          </Button>
          <Spacer height={3.5} />
        </Form>
      )}
    </Formik>
  );
});

export default ChangePassword;
