import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PersonalInforamtionContainer } from "./styles";
import Flex from "../shared/Flex";

interface Props extends RouteComponentProps<any> {
  user: {
    job_title: string;
    secondary_email: string;
    industry_sector: string;
    organisation: string;
    work_phone: string;
    interests: string;
    business_post_code: string;
    business_address: string;
    business_city: string;
    business_county: string;
    business_country: string;
  };
}

const BusinessInforamtion: React.FC<Props> = ({ user }) => {
  return (
    <PersonalInforamtionContainer>
      <Flex>
        <div>
          <p className="title">Job Title:</p>
          <div className="panel">{user.job_title} </div>

          <p className="title">Business Email:</p>
          <div className="panel">{user.secondary_email} </div>

          <p className="title">Industry Sector:</p>
          <div className="panel">{user.industry_sector} </div>

          <p className="title">Organisation:</p>
          <div className="panel">{user.organisation} </div>

          <p className="title">Business Phone:</p>
          <div className="panel">{user.work_phone} </div>

          <p className="title">Interests:</p>
          <div className="panel"> {user.interests} </div>
        </div>
        <div>
          <p className="title">Business Post Code:</p>
          <div className="panel">{user.business_post_code} </div>

          <p className="title">Business Address:</p>
          <div className="panel">{user.business_address} </div>

          <p className="title">Business City:</p>
          <div className="panel">{user.business_city} </div>

          <p className="title">Business County:</p>
          <div className="panel">{user.business_county} </div>

          <p className="title">Business Country:</p>
          <div className="panel">{user.business_country} </div>
        </div>
      </Flex>
    </PersonalInforamtionContainer>
  );
};

export default withRouter(connect(null, null)(BusinessInforamtion));
