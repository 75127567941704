// @ts-nocheck
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { ReactComponent as GridIcon } from "../../svgs/grid.svg";
import { ReactComponent as CalendarIcon } from "../../svgs/calendar.svg";
import Select from "./Select";
import { wordPressAPI } from "../../config";

function useDates(type: "news" | "events" = "news") {
  const [dates, setDates] = useState<any>(null);

  const url =
    type === "news"
      ? `${wordPressAPI}/posts/dates`
      : `${wordPressAPI}/events/dates`;

  useEffect(() => {
    axios.get(url).then(({ data }) => setDates(data));
  }, [url]);

  return dates;
}

const Controls: React.FC<{
  view: string;
  type?: "news" | "events";
  setView: Function;
  showViewControls?: boolean;
  setFilter: Function;
  setDate: Function;
  categories?: { [key: string]: string | number }[];
}> = ({
  view,
  setView,
  setDate,
  setFilter,
  showViewControls = true,
  categories,
  type
}) => {
  const dates = useDates(type);

  return (
    <Container>
      <Filters>
        <div className="type-filter">
          <div>
            <span className="title">Show</span>

            <TypeWrapper>
              <ControlSelect
                onChange={({ target: { value } }) => setFilter(value)}
              >
                <option value="">All</option>

                {categories &&
                  categories.map(category => (
                    <option value={category.id} key={category.slug}>
                      {category.name}
                    </option>
                  ))}
              </ControlSelect>
            </TypeWrapper>
          </div>

          <div>
            <span className="from">From</span>

            <DateWrapper>
              <ControlSelect
                onChange={({ target: { value } }) => setDate(value)}
              >
                <option value="">All</option>
                {dates &&
                  dates.map((date: any) => (
                    <option key={date.timestamp} value={date.timestamp}>
                      {date.date}
                    </option>
                  ))}
              </ControlSelect>
            </DateWrapper>
          </div>
        </div>
      </Filters>

      {showViewControls && (
        <ViewControls>
          <Button
            active={view === "grid"}
            className="button"
            onClick={() => setView("grid")}
          >
            <GridIcon className="grid-icon" />
            Grid
          </Button>

          <Button
            active={view === "calendar"}
            className="button"
            onClick={() => setView("calendar")}
          >
            <CalendarIcon />
            Calendar
          </Button>
        </ViewControls>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Filters = styled.div`
  .type-filter {
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    line-height: 4.4rem;

    .title,
    .from {
      margin-right: 1.5rem;
      font-weight: 300;
    }

    .from {
      margin-left: 2.3rem;

      @media screen and (max-width: 774px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 774px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;

    .from {
      margin-left: 0;
    }
  }
`;

const ViewControls = styled.div`
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: 774px) {
    display: none;
  }

  .button {
    background: transparent;
    border: none;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 2.3rem;
    }
  }
`;

const ControlSelect = styled(Select)`
  height: 4.4rem;
  font-size: 2.4rem;
  padding: 0 1.9rem;
  font-weight: 300;
  background-color: ${({ theme }) => theme.gray4};
  border-radius: 11px;
  color: ${({ theme }) => theme.black};
  background-position: right 15px top 50%, 0 0;
`;

const DateWrapper = styled.div`
  min-width: 18.3rem;
  display: inline-block;
`;

const TypeWrapper = styled.div`
  min-width: 8rem;
  display: inline-block;
`;

interface BtnProps {
  active: boolean;
}

const Button = styled.button<BtnProps>`
  ${({ theme }) => css`
    .grid-icon path {
      stroke: transparent;
      fill: ${theme.black};
    }
  `}

  ${({ active, theme }) =>
    active &&
    css`
      path {
        fill: ${theme.orange};
        stroke: ${theme.orange};
      }

      .grid-icon path {
        stroke: transparent;
        fill: ${theme.orange};
      }

      color: ${theme.orange};
    `}
`;

export default Controls;
