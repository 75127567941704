import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import request from "../../../../utils/request";
import keysToSnakeCase from "../../../../utils/keysToSnakeCase";
import { UPGRADE_PLAN_SUCCESS } from "../../../../redux/constants";

export const usePay = (callback: Function) => {
  const dispatch = useDispatch();

  const pay = async (payload: any) => {
    try {
      const { data } = await request.post(
        "/user-subscription/gift",
        keysToSnakeCase(payload)
      );

      dispatch({
        type: UPGRADE_PLAN_SUCCESS,
        payload: data
      });

      toast.success("Payment successful!");

      callback();
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const payWithPaypal = async (payload: any) => {
    try {
      const { data } = await request.post(
        `/user-subscription/gift`,
        keysToSnakeCase(payload)
      );

      dispatch({
        type: UPGRADE_PLAN_SUCCESS,
        payload: data
      });

      toast.success("Payment successful!");

      callback();
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  return { pay, payWithPaypal };
};
