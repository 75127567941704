// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ReactIdleTimer from "react-idle-timer";
import logout from "../../redux/actions/logOut";

interface Props extends RouteComponentProps {
  logout: Function;
  timeout: number;
}
class IdleTimer extends Component<Props> {
  idleTimer: any = null;

  _onIdle = () => {
    if (this.idleTimer.getRemainingTime() === 0) {
      this.props.logout(this.props.history.push);
    }
  };

  render() {
    return (
      <>
        <ReactIdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onIdle={this._onIdle}
          element={document}
          debounce={250}
          timeout={this.props.timeout}
        />
        {this.props.children}
      </>
    );
  }
}

export default connect(null, {
  logout
})(withRouter(IdleTimer));
