import {
  LOGIN_FAILURE,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_PENDING,
  REGISTER_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_PENDING,
  SUBSCRIBE_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PENDING,
  UPDATE_USER_FAILURE,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_CARD_PENDING,
  CHANGE_CARD_FAILURE,
  CHANGE_CARD_SUCCESS,
  UPDATE_PERSONAL_INFO_FAILURE,
  UPDATE_PERSONAL_INFO_PENDING,
  UPDATE_PERSONAL_INFO_SUCCESS,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  UPGRADE_PLAN_SUCCESS
} from "../constants";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

interface IState {
  loading: boolean;
  success: boolean;
  failed: boolean;
  data: null | object;
}

interface IAction {
  type: string;
  payload: any;
}

const user = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case REGISTER_SUCCESS:
    case CONFIRM_EMAIL_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true
      };

    case LOGIN_PENDING:
    case REGISTER_PENDING:
    case CONFIRM_EMAIL_PENDING:
    case UPDATE_USER_PENDING:
    case CHANGE_PASSWORD_PENDING:
    case CHANGE_CARD_PENDING:
    case UPDATE_PERSONAL_INFO_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };

    case LOGIN_FAILURE:
    case CHANGE_CARD_FAILURE:
    case UPDATE_USER_FAILURE:
    case REGISTER_FAILURE:
    case CONFIRM_EMAIL_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case UPDATE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true
      };

    case LOGIN_SUCCESS:
    case SUBSCRIBE_SUCCESS:
    case UPDATE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        data: action.payload
      };

    case UPGRADE_PLAN_SUCCESS:
    case CHANGE_PAYMENT_METHOD_SUCCESS:
    case CHANGE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        data: action.payload.user
      };

    default:
      return state;
  }
};

export default user;
