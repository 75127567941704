import styled from "styled-components";

export const ModalArea = styled.div<{ sharpEdges: boolean }>`
  width: 60rem;
  height: auto;
  border-radius: ${({ sharpEdges }) => (sharpEdges ? "0" : "2rem")};
  background: ${({ theme }) => theme.white};
  padding-bottom: 3rem;
  margin-top: 2em;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const ModalHeader = styled.header`
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.gray4};

  h1 {
    font-weight: 400;
  }

  #modal-deactivate {
    height: 6rem;
    width: 6rem;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 2rem;

    &:hover {
      background: ${({ theme }) => theme.offWhite};
    }
  }
`;

export const ModalBody = styled.div`
  padding: 3rem 0;
  max-height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &.no-padding {
    padding: 0;
  }

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const SVG = styled.svg`
  stroke: black;
  width: 30px;
  height: 30px;
`;
