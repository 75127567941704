import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseURL } from "../../config";

export const initiatePasswordReset = (payload: {
  email: string;
}) => async () => {
  try {
    const protocol = window.location.protocol;
    const returnURL = `${protocol}//${window.location.host}/reset-password`;
    const completePayload = { ...payload, return_url: returnURL };

    await axios.post(`${apiBaseURL}/reset-password`, completePayload);

    toast.success("Please check your email for further instructions.");
  } catch (err) {
    if (err.response.status >= 400) {
      toast.error(err.response.data.error);
    }
  }
};

export const resetPassword = (payload: {
  token: string;
  password: string;
}) => async () => {
  try {
    await axios.put(`${apiBaseURL}/reset-password`, payload);

    toast.success("Password reset successful.");
  } catch {
    toast.error("You are not allowed to perform this operation!");
  }
};
