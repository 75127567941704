// @ts-nocheck
import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  PageContainer,
  Spacer,
  Button,
  CenterBox,
  PageLoader
} from "../../shared";
import { H1, HR } from "../styles";
import { GoBack } from "../styles";
import { ReactComponent as BackIcon } from "../../../svgs/caret-left.svg";
import { getSingleEvent } from "../../../redux/actions/event";
import { ReactComponent as Calendar } from "../../../svgs/calendar2.svg";
import { ReactComponent as Right } from "../../../svgs/caret-right.svg";
import { PageTitle } from "../../shared/PageTitle";
import useLoading from "../../../hooks/useLoading";

const EventWrapper = styled.div`
  display: flex;
  margin-top: 4rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const EventMeta = styled.div`
  min-width: 20.8rem;
  max-width: 20.8rem;
  margin-right: 7.8rem;

  .add-to-calendar {
    font-size: 1.4rem;
    line-height: 107.5%;
    background: transparent;
    border: none;
    text-decoration: none;
    color: ${({ theme }) => theme.greenishBlack};

    svg {
      margin-right: 0.7rem;
      vertical-align: baseline;
    }
  }

  h2 {
    font-size: 2.4rem;
    line-height: 4.4rem;
    font-weight: 400;
  }

  p {
    font-size: 1.6rem;
    line-height: 150%;
    color: ${({ theme }) => theme.black2};
  }

  .location {
    width: 20.8rem;
  }
`;

const EventInfo = styled.div`
  flex: 1;
  font-size: 1.6rem;
  line-height: 150%;

  figure {
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black2};
    line-height: 150%;
  }
`;

const BackToEvents = styled(GoBack)`
  width: 100%;
  height: 12.2rem;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.offWhite2};
`;

const RegisterButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 10px;
    width: 10px;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    path {
      stroke: ${({ theme }) => theme.orange};
    }
  }
`;

const SingleEvent: React.FC<{
  match: any;
  getSingleEvent: Function;
}> = ({
  match: {
    params: { eventID }
  },
  getSingleEvent
}) => {
  const [isLoading, load] = useLoading();

  useEffect(() => {
    load(getSingleEvent(eventID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  const event = useSelector(({ singleEvent }: any) => singleEvent.data);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        event && (
          <PageContainer>
            <PageTitle
              title={event.post_title}
              description={event.post_content.substr(0, 50) + "..."}
            />
            <Spacer height={8.9} />

            <H1>{event.post_title}</H1>

            <HR />

            <EventWrapper>
              <EventMeta>
                <Spacer height={5.5} />
                <h2>When:</h2>
                <Spacer height={1} />
                <p>
                  {event.events_date}
                  <br />
                  {event.events_starting_time} - {event.events_ending_time}
                </p>

                <Spacer height={1.5} />

                {event.add_to_calender_link && (
                  <a
                    href={event.add_to_calender_link}
                    className="add-to-calendar"
                  >
                    <Calendar />
                    Add to Calendar
                  </a>
                )}

                <Spacer height={6.8} />

                <h2>Location</h2>

                <Spacer height={1} />

                <p className="location">{event.events_location}</p>

                <Spacer height={3.9} />

                <h2>Admission</h2>

                <Spacer height={1} />

                <p>{event.events_ticket_price}</p>

                <Spacer height={5} />

                <RegisterButton
                  as="a"
                  href={event.events_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  large
                >
                  REGISTER
                  <Right />
                </RegisterButton>
              </EventMeta>

              <EventInfo>
                <figure>
                  <img src={event.featured_image} alt="Event" />
                </figure>

                <Spacer height={3.8} />

                <div
                  dangerouslySetInnerHTML={{
                    __html: event.post_content
                      .split("&nbsp;")
                      .join("<br /><br />")
                  }}
                />
              </EventInfo>
            </EventWrapper>
          </PageContainer>
        )
      )}

      <Spacer height={1} />

      <BackToEvents>
        <CenterBox>
          <Link to="/dashboard/events">
            <BackIcon />
            Go Back
          </Link>
        </CenterBox>
      </BackToEvents>
    </>
  );
};

export default connect(null, {
  getSingleEvent
})(SingleEvent);
