import {
  GET_SINGLE_DISCOUNT_FAILURE,
  GET_SINGLE_DISCOUNT_PENDING,
  GET_SINGLE_DISCOUNT_SUCCESS,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_PENDING,
  GET_DISCOUNTS_FAILURE,
  GET_MORE_DISCOUNTS
} from "../constants";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

interface IState {
  loading: boolean;
  success: boolean;
  failed: boolean;
  data: any;
}

interface IAction {
  type: string;
  payload: any;
}

export const discounts = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case GET_DISCOUNTS_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case GET_MORE_DISCOUNTS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          data: [...state.data.data, ...action.payload.data]
        }
      };

    case GET_DISCOUNTS_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false
      };

    case GET_DISCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false
      };

    default:
      return state;
  }
};

export const singleDiscount = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case GET_SINGLE_DISCOUNT_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case GET_SINGLE_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        failed: true,
        success: false
      };

    case GET_SINGLE_DISCOUNT_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false
      };

    default:
      return state;
  }
};
