// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { H1, HR, OUPLink, GoBack } from "./styles";
import { PageContainer, Spacer } from "../shared";
import styled from "styled-components";
import { PageTitle } from "../shared/PageTitle";
import { ReactComponent as BackIcon } from "../../svgs/caret-left.svg";

const AfricanAffairs = () => (
  <>
    <PageTitle
      title="Read African Affairs"
      description="African Affairs is published on behalf of the Royal African Society and
          is the top ranked journal in African Studies. It is an
          inter-disciplinary, peer-reviewed academic journal, with a focus on the
          politics and international relations of Africa."
    />
    <PageContainer>
      <Spacer height={8.9} />
      <H1>
        Read African
        <br />
        Affairs
      </H1>

      <HR />

      <GoBack>
        <Link to="/dashboard">
          <BackIcon />
          Go Back
        </Link>
      </GoBack>

      <Spacer height={7.9} />

      <Text>
        <p className="leading">
          African Affairs is published on behalf of the Royal African Society
          and is the top ranked journal in African Studies. It is an
          inter-disciplinary, peer-reviewed academic journal, with a focus on
          the politics and international relations of Africa.
        </p>

        <p className="mid">
          It also includes sociology, anthropology, economics, history,
          literature, art, music and other studies that will inform debates on
          contemporary African affairs. Each issue of African Affairs contains
          new original research articles, as well as briefings on contemporary
          issues, and research notes on ethical and methodological challenges.
          There is also a substantial section of book reviews, with occasional
          review articles.
        </p>

        <p className="foot">
          <a
            rel="noopener noreferrer"
            target="_blank"
            referrerPolicy="no-referrer-when-downgrade"
            href="https://academic.oup.com/afraf"
          >
            Click here
          </a>{" "}
          and select African Affairs from the list to read the latest issue and
          access previous issues.
        </p>
      </Text>

      <Spacer height={9} />

      <OUPLink
        to=""
        href="https://academic.oup.com/afraf"
        referrerPolicy="no-referrer-when-downgrade"
        rel="noopener"
        target="_blank"
      >
        <span>Read African Affairs</span>
        <img src="/images/oxford.png" alt="oxford-academics" />
      </OUPLink>

      <Spacer height={19.5} />
    </PageContainer>
  </>
);

const Text = styled.div`
  max-width: 100.3rem;

  .leading {
    font-size: 2.4rem;
    line-height: 150%;
  }

  .mid {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 150%;
    font-weight: 300;
  }

  .foot {
    margin-top: 2rem;
    font-size: 1.6rem;

    a {
      color: ${({ theme }) => theme.orange};
    }
  }
`;

export default AfricanAffairs;
