// @ts-nocheck
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);
`;

const SpinnerBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: ${({ theme }) => theme.white};
  border-radius: 3rem;
`;

const FullPageLoader = () => {
  const theme = useContext(ThemeContext);

  return (
    <Overlay>
      <SpinnerBackground>
        <Loader type="Oval" color={theme.orange} height={90} width={90} />
      </SpinnerBackground>
    </Overlay>
  );
};

export default FullPageLoader;
