import React, { useContext } from "react";
import Loader from "react-loader-spinner";
import styled, { ThemeContext } from "styled-components";
import { Button, PageContainer, CenterBox } from "../shared";

const Div = styled.div`
  background: ${({ theme }) => theme.gray4};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
`;

const Wrapper = styled.div`
  height: 10.8rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .left {
    margin-right: 3rem;
  }
`;

const SaveInfo: React.FC<{ handleReset: Function; isSubmitting: boolean }> = ({
  handleReset,
  isSubmitting
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Div>
      <PageContainer>
        <CenterBox>
          <Wrapper>
            <Button
              className="left"
              type="reset"
              large
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" large secondary disabled={isSubmitting}>
              {isSubmitting ? (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Save"
              )}
            </Button>
          </Wrapper>
        </CenterBox>
      </PageContainer>
    </Div>
  );
};

export default SaveInfo;
