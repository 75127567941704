// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { PageContainer, Spacer } from "../../../shared";
import { H1, HR, GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { PageTitle } from "../../../shared/PageTitle";
import { TabsContainer } from "../style";
import ChangePassword from "./ChangePassword";

const Account = () => {
  return (
    <>
      <PageTitle title="Account" description="Manage admin account" />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>Account</H1>

        <HR />

        <GoBack>
          <Link to="/admin/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={7.5} />

        <TabsContainer>
          <Tabs>
            <TabList>
              <PageContainer>
                <div className="tabs">
                  <Tab>Change Password</Tab>
                </div>
              </PageContainer>
            </TabList>

            <PageContainer>
              <TabPanel>
                <ChangePassword />
              </TabPanel>
            </PageContainer>
          </Tabs>
        </TabsContainer>

        <Spacer height={10.5} />
      </PageContainer>
    </>
  );
};

export default Account;
