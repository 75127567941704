import axios from 'axios';
import { wordPressAPI } from '../../config';
import {
  GET_FAQ_FAILURE,
  GET_FAQ_PENDING,
  GET_FAQ_SUCCESS,
} from '../constants';


export const getFAQ = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_FAQ_PENDING
    });

    const { data } = await axios
      .get(`${wordPressAPI}/faq`);

    dispatch({
      type: GET_FAQ_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_FAQ_FAILURE
    });
  }
};