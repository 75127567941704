// @ts-nocheck
import React, { useState } from "react";
import { Formik } from "formik";
import { string, object, ref, boolean } from "yup";
import { Form, H1, Address, Label } from "./styles";
import {
  CenterBox,
  Separator,
  Steps,
  FormGroup,
  InputWrapper,
  Select,
  Input,
  countryList,
  Spacer,
  Button,
  ScrollToTopOnMount,
} from "../shared";
import formHasErrors from "../../utils/formHasErrors";
import KeepInTouch from "../Donation/KeepInTouch";
import { subscribeToNewsletters } from "../../redux/actions/newsletters";

const validationSchema = object().shape({
  firstName: string().required("First name not provided."),
  lastName: string().required("Last name not provided."),
  title: string()
    .notOneOf(["Title *"], "Title is required.")
    .required("Title not provided."),
  email: string()
    .email("Invalid email provided.")
    .required("Email not provided."),
  confirmEmail: string()
    .email()
    .oneOf([ref("email"), ""], "Emails must match."),
  mobile: string().required("Mobile not provided."),
  genderIdentity: string()
    .oneOf(
      ["Male", "Female", "Prefer not to say", "Non-Binary"],
      "Gender is required."
    )
    .required("Gender not provided."),
  postCode: string().required("Post code not provided."),
  address: string().required("Address not provided."),
  city: string().required("City not provided."),
  county: string().required("County not provided."),
  country: string()
    .notOneOf(["Country"], "Country is not provided")
    .required("Country is required."),
  gdpr: boolean(),
  byPost: boolean(),
});

const Step1: React.FC<{
  state: { [key: string]: string | boolean };
  goNext: Function;
  setState: Function;
}> = ({ state, setState, goNext }) => {
  const [selectedNewsletters, setSelectedNewsletters] = useState<string[]>([]);

  return (
    <>
      <ScrollToTopOnMount />

      <Formik
        onSubmit={(giftingData) => {
          setState({
            ...state,
            ...giftingData,
          });

          subscribeToNewsletters({
            interests: selectedNewsletters,
            email: giftingData.email as string,
            first_name: giftingData.firstName as string,
            last_name: giftingData.lastName as string,
          });

          goNext();
        }}
        validationSchema={validationSchema}
        initialValues={state}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          dirty,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <H1>
              The perfect
              <br />
              membership gift
            </H1>
            <CenterBox>
              <Separator />
            </CenterBox>

            <Steps maxSteps={3} step={1} />

            <h2 className="form-title">Your details</h2>

            <FormGroup>
              <InputWrapper>
                <Select
                  value={values.title as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="title"
                  errored={Boolean(touched.title && errors.title)}
                >
                  <option>Title *</option>
                  <option value="Sir">Sir</option>
                  <option value="Revd">Revd</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                  <option value="Miss">Miss</option>
                  <option value="Dame">Dame</option>
                  <option value="Baroness">Baroness</option>
                  <option value="Lord">Lord</option>
                  <option value="Earl">Earl</option>
                  <option value="Rt Hon">Rt Hon</option>
                  <option value="Rt Hon Sir">Rt Hon Sir</option>
                  <option value="Mx">Mx</option>
                </Select>

                <span className="error">{touched.title && errors.title}</span>
              </InputWrapper>

              <InputWrapper>
                <Select
                  value={values.genderIdentity as string}
                  errored={Boolean(
                    touched.genderIdentity && errors.genderIdentity
                  )}
                  name="genderIdentity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Gender *</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Non-Binary">Non-Binary</option>
                </Select>

                <span className="error">
                  {touched.genderIdentity && errors.genderIdentity}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.firstName && errors.firstName)}
                  placeholder="First Name *"
                  name="firstName"
                  type="text"
                  value={values.firstName as string}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />

                <span className="error">
                  {touched.firstName && errors.firstName}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  placeholder="Last Name *"
                  errored={Boolean(touched.lastName && errors.lastName)}
                  name="lastName"
                  type="text"
                  value={values.lastName as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <span className="error">
                  {touched.lastName && errors.lastName}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  value={values.email as string}
                  errored={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  placeholder="Email *"
                  type="email"
                  name="email"
                  onBlur={handleBlur}
                  required
                />
                <span className="error">{touched.email && errors.email}</span>
              </InputWrapper>

              <InputWrapper>
                <Input
                  errored={Boolean(touched.confirmEmail && errors.confirmEmail)}
                  value={values.confirmEmail as string}
                  onChange={handleChange}
                  placeholder="Confirm Email *"
                  name="confirmEmail"
                  type="email"
                  onBlur={handleBlur}
                  required
                />

                <span className="error">
                  {touched.confirmEmail && errors.confirmEmail}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.mobile && errors.mobile)}
                  value={values.mobile as string}
                  onChange={handleChange}
                  placeholder="Phone *"
                  name="mobile"
                  type="text"
                  onBlur={handleBlur}
                  required
                />

                <span className="error">{touched.mobile && errors.mobile}</span>
              </InputWrapper>

              <InputWrapper>
                <Select
                  errored={Boolean(touched.nationality && errors.nationality)}
                  value={values.nationality as string}
                  onChange={handleChange}
                  placeholder="Nationality *"
                  name="nationality"
                  onBlur={handleBlur}
                  required
                >
                  <option value="Nationality">Nationality *</option>
                  {countryList}
                </Select>

                <span className="error">
                  {touched.nationality && errors.nationality}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <Address>
                <Label>Billing Address</Label>
                <FormGroup>
                  <InputWrapper>
                    <Input
                      errored={Boolean(touched.postCode && errors.postCode)}
                      value={values.postCode as string}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Post Code *"
                      name="postCode"
                      type="text"
                    />

                    <span className="error">
                      {touched.postCode && errors.postCode}
                    </span>
                  </InputWrapper>
                </FormGroup>
              </Address>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.address && errors.address)}
                  value={values.address as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Address *"
                  name="address"
                  type="text"
                  required
                />

                <span className="error">
                  {touched.address && errors.address}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.city && errors.city)}
                  value={values.city as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="City *"
                  type="text"
                  name="city"
                  required
                />
                <span className="error">{touched.city && errors.city}</span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.county && errors.county)}
                  value={values.county as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="County *"
                  name="county"
                  type="text"
                  required
                />
                <span className="error">{touched.county && errors.county}</span>
              </InputWrapper>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.country && errors.country)}
                  value={values.country as string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  required
                >
                  <option value="Country">Country *</option>

                  {countryList}
                </Select>

                <span className="error">
                  {touched.country && errors.country}
                </span>
              </InputWrapper>
            </FormGroup>

            <KeepInTouch
              selectedNewsletters={selectedNewsletters}
              setField={setFieldValue}
              setSelectedNewsletters={setSelectedNewsletters}
            />

            <Spacer height={7.3} />

            <Button
              secondary
              large
              type="submit"
              disabled={formHasErrors(errors) || !dirty}
            >
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Step1;
