import { toast } from "react-toastify";
import request from "../../utils/request";
import {
  GET_SUB_PENDING,
  GET_SUB_FAILURE,
  GET_SUB_SUCCESS
} from "../constants";

const getSub = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_SUB_PENDING
    });

    const { data } = await request.get("/user-subscription");

    dispatch({
      type: GET_SUB_SUCCESS,
      payload: data.subscription
    });
  } catch (err) {
    toast.error("An error occurred");

    dispatch({
      type: GET_SUB_FAILURE
    });
  }
};

export default getSub;
