import {
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_PENDING,
  ADMIN_LOGIN_SUCCESS
} from "../constants";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

interface Action {
  type: string;
  payload: any;
}

export const admin = (state = initialState, action: Action) => {
  switch (action.type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case ADMIN_LOGIN_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };

    case ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true
      };

    default:
      return state;
  }
};
