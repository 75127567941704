import styled, { css } from "styled-components";
import Steps from "../shared/Steps";
import { Input } from "../shared";
import Button from "../shared/Button";

export const H1 = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.035em;
  margin: 0 auto;
  color: ${({ theme }) => theme.black};

  @media screen and (max-width: 992px) {
    line-height: 100%;
  }
`;

export const Form = styled.form`
  max-width: 81.3rem;
  margin: 7rem auto 22.9rem;
  padding: 0 3rem;
  text-align: center;

  @media screen and (max-width: 992px) {
    margin: 4rem auto 7.9rem;

    h1 {
      max-width: 28rem;
    }
  }

  .dateGroup {
    display: flex;
    justify-content: space-between;
  }

  .signupButton {
    display: flex;
    justify-content: center;
  }

  a {
    color: ${({ theme }) => theme.orange};
    font-size: 1.6rem;
    line-height: 77.44%;
    text-decoration: none;
  }

  .sub-confirmation-bg {
    text-align: center;
    justify-content: center;
    display: flex;

    .sub-confirmation {
      font-size: 1.8rem;
      color: ${({ theme }) => theme.black2};
      line-height: 140%;

      @media screen and (max-width: 992px) {
        line-height: 130%;
        max-width: 28rem;
      }

      span {
        color: ${({ theme }) => theme.orange};
      }
    }
  }
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.8rem;

  hr {
    border: none;
    background: ${({ theme }) => theme.orange};
    height: 0.5rem;
    width: 7.9rem;
    margin-top: 4rem;
  }
`;

export const FormGroup = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const SignUpButton = styled(Button)`
  margin-top: 3rem;
  padding-left: 9rem;
  padding-right: 9rem;
`;

export const FindAddress = styled(Button)`
  margin: 0;
`;

export const Label = styled.label`
  font-size: 2rem;
  line-height: 1.5rem;
  display: block;
  color: ${({ theme }) => theme.gray};
  padding-left: 3.78rem;
  padding-bottom: 2.3rem;
  margin-top: 4.3rem;
`;

export const DateOfBirth = styled.div`
  width: 48%;
  text-align: left;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const DayMonthInput = styled(Input)`
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  margin-right: 0.75rem;
`;

export const YearInput = styled(Input)`
  width: 12rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
`;

export const InputWrapper = styled.div`
  width: 48%;
`;

export const AddressInputWrapper = styled(InputWrapper)`
  width: 50%;
`;

export const Address = styled.div`
  text-align: left;
  width: 100%;
`;

export const CustomSteps = styled(Steps)`
  margin-bottom: 4.6rem;
`;

export const Notification = styled.p`
  font-size: 1.6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4.6rem;
  width: 40rem;
  line-height: 156.94%;
  color: ${({ theme }) => theme.greenishBlack};

  &.no-width {
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  .resend-email {
    color: ${({ theme }) => theme.orange};
    font-size: 1.6rem;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: inherit;
  }
`;

export const Title = styled.p`
  font-size: 2rem;
  line-height: 77.44%;
  color: ${({ theme }) => theme.greenishBlack};
  margin-bottom: 4.3rem;
`;

export const CodeWrapper = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 0.6rem;
  }
`;

export const Digits = styled.div`
  max-width: 58.3rem;
  display: flex;
  margin: 0 auto;

  .react-code-input {
    input {
      outline: transparent;
      text-align: center;

      &:not(:last-child) {
        margin-right: 6px;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
      &,
      &:hover,
      &:focus {
        -moz-appearance: textfield;
      }
    }
  }
`;

export const CodeInput = styled(Input)`
  text-align: center;
  margin-bottom: 4.6rem;
`;

export const MoreInfoForm = styled(Form)`
  margin-bottom: 9.9rem;
`;

export const CompleteLater = styled.div`
  button,
  a {
    font-size: 1.4rem;
    line-height: 77.44%;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 6.7rem;
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.orange};
  }

  svg {
    height: 0.8rem;
    width: 0.5rem;
    margin-left: 1.6rem;
    vertical-align: middle;
  }
`;

export const Step3Of4 = styled(Steps)`
  margin-bottom: 6.2rem;
`;

export const KeepInTouch = styled.div`
  text-align: left;
  width: 100%;
  background: ${({ theme }) => theme.offWhite};
  border-radius: 1rem;
  padding: 4.1rem 3.5rem;
  font-size: 1.4rem;
  border: 1px solid #e0e0e0;
  margin-top: 3.6rem;

  .title {
    margin-left: 0.1rem;
    font-size: 2.4rem;
    line-height: 140.5%;
    letter-spacing: -0.035em;
    margin-bottom: 2.1rem;
  }

  .paragraph {
    line-height: 150%;
    padding-bottom: 2.1rem;
    margin-bottom: 2.5rem;

    &.last {
      margin-top: 3.3rem;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

export const Events = styled.div`
  label {
    margin-right: 1.8rem;
  }

  line-height: 200%;
`;

export const TermsOfUse = styled.div`
  text-align: center;
  margin-top: 3.6rem;
  font-size: 1.4rem;
  margin-bottom: 4.8rem;
`;

export const CreditCardContainer = styled.div`
  max-width: 57.6rem;
  height: 6rem;
  border-radius: 4.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;

  ${({ theme }) => css`
    background: ${theme.offWhite2};
    border: 1px solid ${theme.gray2};
  `}

  .StripeElement {
    width: 100%;
    margin: 0 2rem;

    .CardField.CardField--ltr {
      font-size: 20px;
    }
  }
`;
