// @ts-nocheck
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import TextTruncate from "react-text-truncate";
import {
  PageContainer,
  Spacer,
  Modal,
  CenterBox,
  ViewMore,
  PageLoader,
  RightBox
} from "../../shared";
import { H1, HR, NewsContent, GoBack, NewsContainer, SVG } from "../styles";
import { ReactComponent as BackIcon } from "../../../svgs/caret-left.svg";
import { ReactComponent as CaretRight } from "../../../svgs/caret-right.svg";
import {
  getDiscounts,
  getMoreDiscounts
} from "../../../redux/actions/discount";
import { PageTitle } from "../../shared/PageTitle";
import useLoading from "../../../hooks/useLoading";
import { numberNewsItems, initialPage } from "../../../config";


const Excerpt = styled.p`
  margin-top: 1.5rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.gray};
  line-height: 1.9rem;
`;

const ViewDeal = styled.div`
  margin-top: 2rem;

  headline {
    cursor: pointer;
  }

  button {
    text-align: left;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.orange};
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

const DeactivateBtn = styled.button`
  background: transparent;
  border: none;
  padding: 0.4rem;
  float: right;
  cursor: pointer;

  &:focus {
    outline-color: transparent;
    box-shadow: none;
  }
`;

const Code = styled.div`
  border: 1px dashed ${({ theme }) => theme.gray3};
  width: 80%;
  height: 7rem;
`;

const DiscountTitle = styled.p`
  text-align: center;
  font-size: 2rem;
`;

const DownloadLink = styled.a`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.orange};
`;

const params = {
  page: initialPage,
  perPage: numberNewsItems
};

const AllDiscounts: React.FC<{
  getDiscounts: Function;
  getMoreDiscounts: Function;
}> = ({ getDiscounts, getMoreDiscounts }) => {
  const discounts = useSelector(({ discounts }: any) => discounts.data);
  const subscription = useSelector((state: any) => state.subscription);
  const [modalActive, setModalActive] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<any>(null);
  const [isLoading, load] = useLoading();

  const [viewMoreParams, setViewMoreParams] = useState(params);

  params.plan = subscription.data.plan || '';

  useEffect(() => {
    load(getDiscounts(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  return (
    <>
      <PageTitle
        title="My Discounts"
        description="See Discounts on the Royal African Society"
      />
      <PageContainer>
        <Spacer height={8.9} />
        <H1>
          My
          <br />
          Discounts
        </H1>
        <HR />
        <GoBack>
          <Link to="/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>
        <Spacer height={10.5} />
        <NewsContainer>
          {isLoading ? (
            <PageLoader />
          ) : (
            discounts.data.map((discount: { [key: string]: string }) => (
              <NewsContent
                key={discount.id}
                featuredImage={discount.featured_image}
                className="discount-bg"
              >
                <p
                  className="cursor"
                  onClick={() => {
                    toggleModal();
                    setSelectedDiscount(discount);
                  }}
                >
                  <div className="headimage">
                    <div></div>
                  </div>
                </p>
                <p className="source">{discount.store_name}</p>

                <p
                  onClick={() => {
                    toggleModal();
                    setSelectedDiscount(discount);
                  }}
                  className="headline"
                >
                  {discount.discount_name}
                </p>

                <TextTruncate
                  line={3}
                  element={Excerpt}
                  truncateText="..."
                  text={discount.post_content}
                />

                <ViewDeal>
                  <button
                    onClick={() => {
                      toggleModal();
                      setSelectedDiscount(discount);
                    }}
                  >
                    View Deal
                  </button>
                </ViewDeal>
              </NewsContent>
            ))
          )}
        </NewsContainer>
        <Spacer height={9.4} />
        
        {!isLoading && discounts.pageCount > viewMoreParams.page && (
          <CenterBox>
            <ViewMore
              onClick={() => {
                const newParams = {
                  ...viewMoreParams,
                  page: viewMoreParams.page + 1
                };

                setViewMoreParams(newParams);

                getMoreDiscounts(newParams);
              }}
            >
              View more discounts
              <CaretRight />
            </ViewMore>
          </CenterBox>
        )}
        <Modal
          title="discount"
          modalActive={modalActive}
          toggleModal={toggleModal}
          type="discount"
        >
          <RightBox>
            <DeactivateBtn id="modal-deactivate" onClick={toggleModal}>
              <SVG viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </SVG>
            </DeactivateBtn>
          </RightBox>

          <DiscountTitle>
            {selectedDiscount && selectedDiscount.discount_name}
          </DiscountTitle>

          <Spacer height={3} />

          <CenterBox>
            <Code>
              <CenterBox>
                <h1>{selectedDiscount && selectedDiscount.discounts_code}</h1>
              </CenterBox>
            </Code>
          </CenterBox>

          <Spacer height={2} />

          {selectedDiscount && selectedDiscount.discount_document_upload && (
            <CenterBox>
              <DownloadLink
                href={selectedDiscount.discount_document_upload}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Instructions
              </DownloadLink>
            </CenterBox>
          )}

          <Spacer height={4} />
        </Modal>
      </PageContainer>
    </>
  );
};

export default connect(null, { getDiscounts, getMoreDiscounts })(AllDiscounts);
