// @ts-nocheck
import React, { Component, Fragment } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CreditCardContainer } from "./styles";
import { Button, Spacer } from "../shared";
import supportPaymentOptions from "../../svgs/supported-cards.svg";
import subscribe from "../../redux/actions/subscribe";
import { parseQueryString } from "../../utils/queryString";
import { initializeData, removeRegData } from "./regUtils";

interface Props extends RouteComponentProps {
  [key: string]: any;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  loading: boolean;
}

class CheckoutForm extends Component<Props, State> {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    loading: false,
  };

  submit = async () => {
    try {
      this.setState({
        loading: true,
      });

      const name = `${this.state.firstName} ${this.state.lastName}`;
      const { token } = await this.props.stripe.createToken({ name });
      const { plan } = parseQueryString(this.props.location.search);

      const subscriptionData = {
        plan,
        ...this.state,
        paymentSource: token.id,
      };

      await this.props.subscribe(subscriptionData, this.props.history.push);

      await removeRegData();
    } catch (err) {
      toast.error(err.message());
    }
  };

  componentDidMount() {
    initializeData().then((data: any) => {
      this.setState(data);
    });
  }

  render() {
    return (
      <Fragment>
        <CreditCardContainer>
          <CardElement
            style={{
              base: {
                fontSize: "14px",
                "::placeholder": {
                  color: "#bdbdbd",
                },
              },
            }}
          />
        </CreditCardContainer>

        <Spacer height={2.1} />

        <Button
          type="button"
          onClick={this.submit}
          large
          disabled={this.state.loading}
        >
          {this.state.loading ? (
            <Loader type="Oval" color="#ffffff" height={20} width={20} />
          ) : (
            "Pay"
          )}
        </Button>

        <Spacer height={2.65} />

        <img src={supportPaymentOptions} alt="Support payment options" />
      </Fragment>
    );
  }
}

const TransformedCheckoutForm = injectStripe(withRouter(CheckoutForm));

export default connect(
  null,
  {
    subscribe,
  }
)(TransformedCheckoutForm);
