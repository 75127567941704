import styled from "styled-components";

export const PersonalInforamtionContainer = styled.div`
  text-align: center;

  p {
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 1.2rem;
  }

  .title {
    color: ${({ theme }) => theme.orange};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  .panel {
    display: inline-flex;
    height: 4.6rem;
    align-items: left;
    border-radius: 6px;
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;