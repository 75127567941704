import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GET_DONATIONS, GET_DONATION } from "../../../../redux/constants";
import { adminRequest } from "../../../../utils/request";

export const useGetDonations = () => {
  const dispatch = useDispatch();
  const donations: any = useSelector((state: any) => state.donations);

  const getDonations = async (page = 1, page_size = 10) => {
    try {
      const { data } = await adminRequest.get(
        `/donations?page=${page}&page_size=${page_size}`
      );

      dispatch({
        type: GET_DONATIONS,
        payload: data.donations
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [donations, getDonations] as const;
};

export const useGetDonation = () => {
  const dispatch = useDispatch();
  const donation: any = useSelector((state: any) => state.adminDonation);

  const getDonation = async (id: number) => {
    try {
      const { data } = await adminRequest.get(`/donations/${id}`);

      dispatch({
        type: GET_DONATION,
        payload: data.donation
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [donation, getDonation] as const;
};
