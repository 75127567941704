// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { Input, Select, Button } from "../../../shared";

const SearchBtn = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 6.7rem;
`;

const Form = styled.form`
  display: flex;

  .select {
    width: 40rem;
    margin: 0 2rem;
  }
`;

interface Props {
  setFilter: (filter: {
    searchTerm?: string;
    filter?: string;
    page?: number;
    page_size?: number;
  }) => void;
  loading: boolean;
}

const Search: React.FC<Props> = ({ setFilter, loading }) => {
  const [formState, setFormState] = useState({
    searchTerm: "",
    filter: ""
  });

  const safeSetState = (data: { name: string; value: string }) => {
    setFormState({
      ...formState,
      [data.name]: data.value
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    safeSetState({
      name: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFilter(formState);
      }}
    >
      <Input
        type="search"
        placeholder="Search members"
        onChange={handleChange}
        value={formState.searchTerm}
        name="searchTerm"
      />
      <Select
        className="select"
        value={formState.filter}
        onChange={handleChange}
        name="filter"
      >
        <option value="">Filter by</option>
        <option value="student">Student</option>
        <option value="individual">Individual</option>
        <option value="friend">Friend</option>
        <option value="lifetime">Lifetime</option>
        <option value="corporate">Corporate</option>
        <option value="artsAndCulture">Arts and Culture</option>
        <option value="consultant">Consultant</option>
      </Select>
      <SearchBtn type="submit" large disabled={loading}>
        Search
      </SearchBtn>
    </Form>
  );
};

export default Search;
