import React from "react";
import { NewsGrid, SubscribersCard } from "../../Dashboard/styles";
import ViewAll from "../../Dashboard/ViewAll";
import { ReactComponent as RightIcon } from "../../../svgs/caret-right.svg";
import { Summary, CardContent } from "./style";

const Events: React.FC<{ count: { total: number; active: number } }> = ({
  count
}) => {
  return (
    <NewsGrid>
      <SubscribersCard>
        <CardContent className="subscribersContent">
          <Summary>
            <p>{count.total}</p>
          </Summary>
          <p className="title">Members</p>

          <Summary>
            <p>
              {count.active}
              <br />
              <span>Active</span>
            </p>
          </Summary>

          <ViewAll to="/admin/dashboard/subscribers" className="events">
            View all <RightIcon />
          </ViewAll>
        </CardContent>
      </SubscribersCard>
    </NewsGrid>
  );
};

export default Events;
