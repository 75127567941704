// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { H1, HR, OUPLink } from "./styles";
import { PageContainer, Spacer } from "../shared";
import Events from "./Events";
import News from "./News";
import Discounts from "./Discounts";

const Home: React.FC = () => {
  const user = useSelector((state: any) => state.user.data);
  const subscription = useSelector((state: any) => state.subscription);

  return (
    <PageContainer>
      <Spacer height={8.9} />
      <H1>
        Hi {user.first_name},
        <br />
        Welcome
      </H1>

      <HR />

      <Spacer height={7.65} />

      <News />

      <Spacer height={7.65} />

      <Events />

      <Spacer height={7.65} />

      <Discounts />

      <Spacer height={7.65} />

      {
        subscription.data.plan !== 'artsAndCulture' && (
          <OUPLink as={Link} to="/dashboard/african-affairs">
            Read African Affairs
            <img src="/images/oxford.png" alt="oxford-academics" />
          </OUPLink>
        )
      }

      <Spacer height={7.65} />
    </PageContainer>
  );
};

export default Home;
