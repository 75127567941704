import axios from "axios";
import { wordPressAPI } from "../../config";
import {
  GET_ALL_NEWS_FAILURE,
  GET_ALL_NEWS_PENDING,
  GET_ALL_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAILURE,
  GET_SINGLE_NEWS_PENDING,
  GET_SINGLE_NEWS_SUCCESS,
  GET_MORE_NEWS
} from "../constants";
import { toast } from "react-toastify";

interface Params {
  perPage: number;
  page: number;
  date: number;
  filter: number | string;
}

export const getNews = (params: Params) => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_ALL_NEWS_PENDING
    });

    const { data } = await axios.get(
      `${wordPressAPI}/posts?posts_date=${params.date || ""}&per_page=${
        params.perPage
      }&page=${params.page}&category_id=${params.filter || ""}`
    );

    dispatch({
      type: GET_ALL_NEWS_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_NEWS_FAILURE
    });
  }
};

export const getMoreNews = (params: Params) => async (dispatch: Function) => {
  try {
    const { data } = await axios.get(
      `${wordPressAPI}/posts?posts_date=${params.date || ""}&per_page=${
        params.perPage
      }&page=${params.page}&category_id=${params.filter || ""}`
    );

    dispatch({
      type: GET_MORE_NEWS,
      payload: data
    });
  } catch (err) {
    console.log(err);
    toast.error("An error occurred.");
  }
};

export const getSingleNews = (slug: string) => async (dispatch: Function) => {
  try {
    dispatch({
      type: GET_SINGLE_NEWS_PENDING
    });

    const { data } = await axios.get(`${wordPressAPI}/post?slug=${slug}`);

    dispatch({
      type: GET_SINGLE_NEWS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_NEWS_FAILURE
    });
  }
};
