import request from "../../utils/request";
import keysToSnakeCase, { ObjType } from "../../utils/keysToSnakeCase";
import {
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_PERSONAL_INFO_FAILURE,
  UPDATE_PERSONAL_INFO_PENDING,
  UPDATE_PERSONAL_INFO_SUCCESS
} from "../constants";
import { toast } from "react-toastify";

export const updateProfile = (payload: ObjType, callback?: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: UPDATE_PERSONAL_INFO_PENDING
    });

    const { data } = await request.put("/user", keysToSnakeCase(payload));

    dispatch({
      type: UPDATE_PERSONAL_INFO_SUCCESS,
      payload: data.user
    });

    if (callback) {
      callback();
    }

    toast.success("Profile updated!");
  } catch (err) {
    dispatch({
      type: UPDATE_PERSONAL_INFO_FAILURE
    });

    toast.error("Failed. Please retry later.");
  }
};

export const changePassword = (payload: ObjType, callback: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_PENDING
    });

    await request.post("/change-password", keysToSnakeCase(payload));

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS
    });

    toast.success("Password changed successfully!");

    callback();
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD_FAILURE
    });

    toast.error("An error occurred!");
  }
};
