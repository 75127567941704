import {
  FIND_ADDRESSES_SUCCESS,
  FIND_ADDRESSES_FAILURE,
  FIND_ADDRESSES_PENDING
} from '../constants';

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null,
};

interface IState {
  loading: boolean,
  success: boolean,
  failed: boolean,
  data: null | object,
}

interface IAction {
  type: string,
  payload: any,
};

const addresses = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case FIND_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        data: action.payload,
      }

    case FIND_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true,
      }

    case FIND_ADDRESSES_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        failed: false,
      }

    default:
      return state;
  }
}

export default addresses;
