import styled, { css } from 'styled-components';

const GhostButton = styled.button<{ to?: string }>`
  width: 19.3rem;
  height: 4.6rem;
  background: transparent;
  text-transform: uppercase;
  font-size: 1.8rem;
  border-radius: 3.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.orange};
      border-color: ${theme.orange};
    ` }
  }

  ${({ theme }) => css`
    border: 2px solid ${theme.white};
    color: ${theme.white}
`}
`;

export default GhostButton;
