import { string, object, number } from "yup";
import format from "date-fns/format";

export const personalInfo = object().shape({
  firstName: string()
    .required("First name not provided.")
    .nullable(),
  title: string()
    .notOneOf(["Title"], "Title is required.")
    .required("Title not provided.")
    .nullable(),
  genderIdentity: string()
    .oneOf(
      ["Male", "Female", "Prefer not to say", "Non-Binary"],
      "Gender is required."
    )
    .required("Gender not provided.")
    .nullable(),
  lastName: string()
    .required("Last name not provided.")
    .nullable(),
  phone: string()
    .required("Phone number is required.")
    .nullable(),
  day: number()
    .min(1, "Day is invalid.")
    .max(31, "Day is invalid.")
    .required("Day is not provided.")
    .nullable(),
  month: number()
    .min(1, "Day is invalid.")
    .max(12, "Day is invalid.")
    .required("Month is not provided.")
    .nullable(),
  year: number()
    .max(Number(format(Date.now(), "YYYY")), "Invalid year.")
    .required("Year is not provided.")
    .nullable()
});

export const professionalInfo = object().shape({
  jobTitle: string().nullable(),
  roleLevel: string().nullable(),
  secondaryEmail: string()
    .email("Invalid email provided.")
    .nullable(),
  organisation: string().nullable(),
  industrySector: string().nullable(),
  workPhone: string().nullable(),
  interests: string().nullable()
});
