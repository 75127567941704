// @ts-nocheck
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Steps,
  Separator,
  Spacer,
  CenterBox,
  ScrollToTopOnMount
} from "../shared";
import CheckoutForm from "./CheckoutForm";
import { stripeKey } from "../../config";
import { Form, H1 } from "./styles";
import { parseQueryString } from "../../utils/queryString";
import { formatToGBP } from "../../utils/currency";
import { useRegistrationTimer } from "./hooks";

enum amounts {
  student = 40,
  individual = 85,
  friend = 120,
  lifetime = 1500,
  corporate = 2470,
  artsAndCulture = 35,
  consultant = 745,
}

const needsConfirmation = ["student", "lifetime"];

const Step4: React.FC<RouteComponentProps> = ({ location }) => {
  useRegistrationTimer();

  const { plan } = parseQueryString(location.search);

  let planToDisplay = plan;

  if(planToDisplay === 'artsAndCulture'){
    planToDisplay = 'Arts & Culture';
  }

 
  return (
    <>
      <ScrollToTopOnMount />
      <Form>
        <H1>
          Enter Your
          <br />
          Card Details
        </H1>

        <CenterBox>
          <Separator />
        </CenterBox>

        <div className="sub-confirmation-bg">
          <div>
            <p className="sub-confirmation">
              You are subscribing to the <span>{planToDisplay}</span> plan for{" "}
              <span>{formatToGBP(Number(amounts[plan]), 0)}</span> a year.
              {!needsConfirmation.includes(plan) &&
                ` The subscription will renew automatically, and you will
                  receive a reminder email one month before your subscription
                  ends. You can cancel your membership at any time.`}
            </p>
          </div>
        </div>


        <Spacer height={5.7} />

        <Steps maxSteps={4} step={4} activateLinks />

        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <CheckoutForm />
          </Elements>
        </StripeProvider>
      </Form>
    </>
  );
};

export default withRouter(Step4);
