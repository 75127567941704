import {
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_PENDING,
  SUBSCRIBE_FAILURE,
  UPGRADE_PLAN_PENDING,
  UPGRADE_PLAN_FAILURE,
  UPGRADE_PLAN_SUCCESS,
  GET_SUB_SUCCESS,
  GET_SUB_PENDING,
  GET_SUB_FAILURE,
  CANCEL_SUB_SUCCESS,
  CANCEL_SUB_PENDING,
  CANCEL_SUB_FAILURE,
  RENEW_PLAN_FAILURE,
  RENEW_PLAN_PENDING,
  RENEW_PLAN_SUCCESS,
  UPDATE_SUB,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_PENDING,
  CHANGE_PAYMENT_METHOD_FAILURE
} from "../constants";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null
};

interface IState {
  loading: boolean;
  success: boolean;
  failed: boolean;
  data: null | object;
}

interface IAction {
  type: string;
  payload: any;
}

const user = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SUBSCRIBE_SUCCESS:
    case GET_SUB_SUCCESS:
    case CANCEL_SUB_SUCCESS:
    case RENEW_PLAN_SUCCESS:
    case UPDATE_SUB:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        data: action.payload
      };

    case UPGRADE_PLAN_SUCCESS:
    case CHANGE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        data: action.payload.subscription
      };

    case SUBSCRIBE_PENDING:
    case RENEW_PLAN_PENDING:
    case CHANGE_PAYMENT_METHOD_PENDING:
    case GET_SUB_PENDING:
    case UPGRADE_PLAN_PENDING:
    case CANCEL_SUB_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };

    case GET_SUB_FAILURE:
    case RENEW_PLAN_FAILURE:
    case CANCEL_SUB_FAILURE:
    case SUBSCRIBE_FAILURE:
    case UPGRADE_PLAN_FAILURE:
    case CHANGE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true
      };

    default:
      return state;
  }
};

export default user;
