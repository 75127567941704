import styled, { css } from "styled-components";

export const Calendar = styled.div`
  .fc-toolbar.fc-header-toolbar {
    display: none;
  }

  .fc-event {
    background: ${({ theme }) => theme.orange};;
    border: none;
    font-size: 10px;
    color: ${({ theme }) => theme.white};
    margin-top: 3rem !important;
    padding: 2px;
    /* display: inline-block; */
    /* width: 100px; */


    /* &:hover {
      color: ${({ theme }) => theme.orange};
      cursor: pointer;
    } */
  }

  .fc-day-number {
    display: block;
    width: 100%;
    margin-top: 1.2rem;
    margin-left: 1rem;
    font-size: 1.6rem;
    float: none !important;
    font-family: "Brown";
  }

  th {
    height: 4.3rem;
    font-size: 1.6rem;
    line-height: 107.5%;
    text-transform: uppercase;
    font-weight: 400;
    vertical-align: middle !important;

    ${({ theme }) => css`
      color: ${theme.white};
      background: ${theme.orange};
    `};
  }
`;

export const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34.2rem, 1fr));
  grid-auto-rows: 39.16rem;
  gap: 1rem 1rem;
  grid-template-rows: repeat(auto-fill, 39.16rem);
`;

export const ViewMore = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.orange};
  font-size: 2.4rem;
  line-height: 107.5%;
  cursor: pointer;

  svg {
    height: 14px;
    width: 9px;
    margin-left: 2rem;
  }
`;

export const EventCard = styled.div`
  border-radius: 15px;
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  &:first-child {
    grid-column: 1 / 3;

    @media screen and (max-width: 774px) {
      grid-column: 1;
    }
  }
`;
