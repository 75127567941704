import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function useRedirectToDashboard() {
  const user = useSelector((state: any) => state.user.data);
  const history = useHistory();

  if (user) {
    history.push("/dashboard");
  }
}

export default useRedirectToDashboard;
