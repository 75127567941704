// @ts-nocheck
import React, { Fragment, useContext } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";
import { CreditCardContainer } from "./styles";
import { Button, Spacer, CenterBox } from "../../../shared";
import supportPaymentOptions from "../../../../svgs/supported-cards.svg";
import useLoading from "../../../../hooks/useLoading";
import { usePay } from "./hooks";
import { useSelector } from "react-redux";

interface Props extends RouteComponentProps {
  [key: string]: any;
}

const CheckoutForm: React.FC<Props> = ({ stripe, plan, history }) => {
  const { pay } = usePay(() => history.push("/dashboard/account"));
  const user = useSelector((state: any) => state.user.data);
  const [isLoading, load] = useLoading();
  const theme = useContext(ThemeContext);

  const submit = async () => {
    const name = `${user.first_name} ${user.last_name}`;
    const { token } = await stripe.createToken({ name });
    const paymentSource = token.id;

    const payload = {
      plan,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      paymentSource,
      paymentProvider: "stripe"
    };

    await pay(payload);
  };

  return (
    <Fragment>
      <CreditCardContainer>
        <CardElement
          style={{
            base: {
              fontSize: "14px",
              "::placeholder": {
                color: "#bdbdbd"
              }
            }
          }}
        />
      </CreditCardContainer>

      <Spacer height={3.1} />

      <CenterBox>
        <Button
          type="button"
          large
          onClick={() => load(submit())}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader type="Oval" color={theme.white} height={20} width={20} />
          ) : (
            "Pay"
          )}
        </Button>
      </CenterBox>

      <Spacer height={5.7} />

      <CenterBox>
        <img src={supportPaymentOptions} alt="Support payment options" />
      </CenterBox>
    </Fragment>
  );
};

const TransformedCheckoutForm = injectStripe(withRouter(CheckoutForm));

export default TransformedCheckoutForm;
