// @ts-nocheck
import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { PageContainer, Spacer } from "../shared";
import { H1, HR, TabsContainer } from "./styles";
import ChangePassword from "./ChangePassword";
import Billing from "./Billing";
import getSub from "../../redux/actions/getSub";
import { PageTitle } from "../shared/PageTitle";

const Account: React.FC<{ getSub: Function }> = ({ getSub }) => {
  const user = useSelector((state: any) => state.user.data);
  const subscription = useSelector((state: any) => state.subscription);

  useEffect(() => {
    getSub();
  }, [getSub]);

  return (
    <>
      <PageTitle
        title="Personal Details"
        description="Your personal details on the Royal African Society"
      />
      <PageContainer>
        <Spacer height={8.9} />
        <H1>Your Account Details</H1>
        <HR />

        <Spacer height={9.8} />
      </PageContainer>

      <TabsContainer>
        <Tabs>
          <TabList>
            <PageContainer>
              <div className="tabs">
                <Tab>Billing</Tab>
                <Tab>Password</Tab>
              </div>
            </PageContainer>
          </TabList>

          <PageContainer>
            <Spacer height={7.4} />
            <TabPanel>
              {subscription.data && (
                <Billing user={user} subscription={subscription.data} />
              )}
            </TabPanel>
            <TabPanel>
              <ChangePassword />
            </TabPanel>
          </PageContainer>
        </Tabs>
      </TabsContainer>
    </>
  );
};

export default connect(
  null,
  {
    getSub,
  }
)(Account);
