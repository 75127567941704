// @ts-nocheck
import styled, { css } from "styled-components";

export const Form = styled.form`
  width: 80.8rem;
  margin: 7rem auto 22.9rem;
  text-align: center;

  @media screen and (max-width: 992px) {
    width: 90%;
  }

  .gift {
    height: 400px;
    width: 100%;
  }

  .sub-confirmation {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.black2};
    line-height: 150%;

    span {
      color: ${({ theme }) => theme.orange};
    }
  }

  .donate-message {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.black2};

    span {
      color: ${({ theme }) => theme.orange};
    }
  }

  .form-title {
    font-size: 2.4rem;
    line-height: 77.44%;
    margin-top: 6.4rem;
    margin-bottom: 3.4rem;
  }

  .supported-cards {
    margin-top: 3.3rem;
  }

  a {
    color: ${({ theme }) => theme.orange};
    font-size: 1.6rem;
    line-height: 77.44%;
    text-decoration: none;
  }
`;

export const H1 = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.035em;
  width: 44rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.black};

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  width: 48%;
`;

export const Address = styled.div`
  text-align: left;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 2rem;
  line-height: 1.5rem;
  display: block;
  color: ${({ theme }) => theme.gray};
  padding-left: 3.78rem;
  padding-bottom: 2.3rem;
  margin-top: 4.3rem;
`;

export const CreditCardContainer = styled.div`
  width: 57.6rem;
  height: 6rem;
  border-radius: 4.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  ${({ theme }) => css`
    background: ${theme.offWhite2};
    border: 1px solid ${theme.gray2};
  `}

  .StripeElement {
    width: 100%;
    margin: 0 2rem;

    .CardField.CardField--ltr {
      font-size: 20px;
    }
  }
`;
