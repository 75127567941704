import localforage from 'localforage';
import { LOGOUT } from '../constants';

const logOut = () => async (dispatch: Function) => {
  await localforage.removeItem('token');
  await localforage.removeItem('admin_token');

  dispatch({
    type: LOGOUT,
  });
};

export default logOut;
