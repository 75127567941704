import styled from 'styled-components';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(39.16rem, 1fr));
  grid-auto-rows: 39.16rem;
  gap: 1rem 1rem;
  grid-template-rows: repeat(auto-fill, 39.16rem);
`;

export default Grid;
