// @ts-nocheck

import React, {useEffect} from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/react-activity.css";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./styles/theme";
import Routes from "./Routes";
import GlobalStyle from "./styles/GlobalStyle";
import storage from "./redux/store";
import ErrorBoundary from "./ErrorBoundary";
import { PageLoader } from "./components/shared";
import tempClearCache from './utils/clear';

const { store, persistor } = storage();

const App: React.FC = () => {
  useEffect(() => {
    tempClearCache();
  }, [])
  
  return (
    <ErrorBoundary>
      { /* @ts-ignore  */ }
      <ThemeProvider theme={theme}>
        <Provider store={store}>
        { /* @ts-ignore  */ }
          <GlobalStyle />
          { /* @ts-ignore  */ }
          <ToastContainer
            style={{
              fontSize: "1.6rem"
            }}
            closeButton={false}
            autoClose={2500}
            hideProgressBar={true}
          />
          { /* @ts-ignore  */ }
          <PersistGate loading={<PageLoader />} persistor={persistor}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Royal African Society - Help us promote the African Society in
                the UK!
              </title>
              <link rel="canonical" href="" />
              <meta
                name="description"
                content="We are the Royal African Society - Help us promote the African Society in the UK!"
              />
            </Helmet>
            <Routes />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
