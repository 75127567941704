import {
  GET_SINGLE_EVENT_SUCCESS,
  GET_SINGLE_EVENT_PENDING,
  GET_SINGLE_EVENT_FAILURE
} from '../constants';

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: null,
};

interface IState {
  loading: boolean,
  success: boolean,
  failed: boolean,
  data: null | object,
}

interface IAction {
  type: string,
  payload: any,
}

const singleEvent = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case GET_SINGLE_EVENT_SUCCESS:
      return {
        loading: false,
        failed: false,
        success: true,
        data: action.payload,
      };

    case GET_SINGLE_EVENT_PENDING:
      return {
        ...state,
        failed: false,
        success: false,
        loading: true,
      };

    case GET_SINGLE_EVENT_FAILURE:
      return {
        ...state,
        failed: true,
        success: false,
        loading: false
      };

    default:
      return state;
  }
}

export default singleEvent;
