import styled from 'styled-components';

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export default Flex;
