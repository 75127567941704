import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Brown';
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    background: ${theme.white};
    font-size: ${theme.baseFontSize};
  }

  .Toastify__toast--success {
    background: ${theme.orange}
  }

  .cursor {
    cursor: pointer;
  }
`;

export default GlobalStyle;
