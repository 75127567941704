import { string, object, number, ref, boolean } from "yup";
import format from "date-fns/format";

export const step1ValidationSchema = object().shape({
  firstName: string().required("First name not provided."),
  title: string()
    .notOneOf(["Title"], "Title is required.")
    .required("Title not provided."),
  genderIdentity: string()
    .oneOf(
      ["Male", "Female", "Prefer not to say", "Non-Binary"],
      "Gender is required."
    )
    .required("Gender not provided."),
  lastName: string().required("Last name not provided."),
  email: string()
    .email("Invalid email provided.")
    .required("Email not provided."),
  confirmEmail: string()
    .email()
    .oneOf([ref("email"), ""], "Emails must match."),
  password: string()
    .min(8, "Password have at least 8 characters.")
    .required("Password not provided."),
  confirmPassword: string().oneOf(
    [ref("password"), ""],
    "Passwords must match."
  ),
  day: number()
    .min(1, "Day is invalid.")
    .max(31, "Day is invalid.")
    .required("Day is not provided."),
  month: number()
    .min(1, "Day is invalid.")
    .max(12, "Day is invalid.")
    .required("Month is not provided."),
  year: number()
    .max(Number(format(Date.now(), "YYYY")), "Invalid year.")
    .required("Year is not provided."),
  phone: string()
    .min(5, "Invalid phone number.")
    .required("Phone not provided"),
  postCode: string().required("Post code not provided."), // and this too
  address: string().required("Address not provided."),
  city: string().required("City not provided."),
  county: string().required("County not provided."),
  country: string()
    .notOneOf(["Country"], "Country is not provided")
    .required("Country is required."),
  nationality: string()
    .notOneOf(["Nationality"], "Nationality is not provided.")
    .required("Nationality is required.")
});

export const step3ValidationSchema = object().shape({
  jobTitle: string(),
  roleLevel: string(),
  secondaryEmail: string().email("Invalid email provided."),
  organisation: string(),
  industrySector: string(),
  website: string(),
  workPhone: string(),
  interests: string(),
  businessAddress: string(),
  businessPostCode: string(),
  businessCity: string(),
  businessCounty: string(),
  businessCountry: string().notOneOf(["Country"], "Country is invalid."),
  byPost: boolean().required()
});
