const theme = {
  orange: "#EF6C1D",
  orange2: "#E99B3F",
  deepGreen: "#123234",
  green: "#56D18A",
  green2: "#0B2B2D",
  black: "#000",
  black2: "#333",
  gray: "#828282",
  gray2: "#e0e0e0",
  gray3: "#bdbdbd",
  gray4: "#efefef",
  gray5: "#fcfcfc",
  gray6: "#f8f8f8",
  white: "#fff",
  baseFontSize: "10px",
  border: "#e5e5e5",
  offWhite: "#f2f2f2",
  offWhite2: "#fbfbfb",
  greenishBlack: "#4f4f4f",
  skyBlue: "#2F9AD6",
  red: "#ff0000",
  paypalBg: "#0270B9",
  paypalBgHover: "#0270B9",
};

export default theme;
